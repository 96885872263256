import {
  FaEdit,
  FaRegFileAlt,
  FaRegTrashAlt,
  FaSyncAlt,
  FaDownload,
  FaFax,
} from "react-icons/fa";
import { useEffect, useState, useRef, ChangeEvent } from "react";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
} from "@mui/material";
import { Control, UseFormTrigger, useForm } from "react-hook-form";
import FileSaver from "file-saver";
import { indexOf, isEmpty } from "lodash";
import { useParams } from "react-router";

import { flexAlignCentre } from "../../../../../styles/mui/styles/display";
import { accent, colors } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  ActionSection,
  AddDocumentSection,
  BodyTable,
  DocumentData,
  DocumentDetailsTableCell,
  DocumentTitle,
  DocumentTypeIcon,
  FileIcon,
  Header,
  HeaderItem,
  HorizontalDivider,
  ShowIcon,
  TabHorizontalDivider,
  TableWidth,
  addedDocumentField,
  RefreshIconStyle,
  DocumentName,
  DocumentNameUnderline,
  DisabledDocumentLink,
  DisabledDownloadIcon,
  HeaderTableCell,
  SendFaxGrid,
} from "../styles/DocumentReview";
import {
  BlobDoc,
  BlobData,
  ReAuthReviewBlobDoc,
  AddFileFormDropdown,
} from "../../../../../models/Document";
import {
  checkSendFaxDocType,
  extractDocType,
  getList,
  getValue,
  isNil,
  length,
} from "../../../../../utils";
import { formatDate } from "../../../../../utils/DateTime";
import { HeaderLabelDocumentReview } from "../../../../../constants/Constants";
import {
  CreatedByResponse,
  DocumentSourceResponse,
} from "../../../../../models/Api/Master";
import {
  DocumentType,
  UserDetail,
  ServiceRequestNumberMaster,
  LetterCopyTypeMaster,
} from "../../../../../models/Master";
import {
  FaChevronCircleDownIcon,
  FaChevronCircleUpIcon,
} from "../styles/requestStyle";
import {
  AttachLetterResponse,
  DocumentDownloadResponse,
  DocumentFileResponse,
} from "../../../../../models/Api/Document";
import { DateFormats } from "../../../../../constants/Date";
import {
  MessageType,
  ResponseMessages,
} from "../../../../../constants/ToastMessage";
import { ReferralResponseMessages } from "../../../../../constants/AllPatientRecord";
import { UserDetailsPayload } from "../../../../../models/User";
import SnackbarUtils from "../../../../../utils/SnackbarProvider";
import {
  DocumentDropDownButtons,
  documentDropDownButtons,
  DocumentTabType,
  ReferralDocumentFileDispatchTypes,
  Source,
  DocumentComponentTitle,
  DocumentFieldLabel,
  DocumentFieldName,
} from "../../../../../constants/Document";
import { DocumentDownloadDispatchTypes } from "../../../../../constants/Document";
import { getDocTypeIcon } from "../../../../../utils/DocIcons";
import Permission from "../../../../Permission";
import { PatientRecordsAccess } from "../../../../../constants/Permission";
import { IconDisabled } from "../../../../../styles/common/style";
import { documentFaxMaxSize, documentMaxSize, documentMinSize } from "../../../../../constants/Config";
import { FaxSentStatus } from "../../../../../constants/Fax";
import { InitiateFaxSendingResponse } from "../../../../../models/Api/Authorization";
import { Checkbox } from "../../../../../components/custom/checkbox";
import AddFileForm from "./AddFileForm";
import { PatientInformationViewResponse } from "../../../../../models/Api/PatientInformation";
import { rules } from "../../../../../utils/validation/Validation";
import { v4 as uuidv4 } from "uuid";
import DropDownMenu from "../../../../../components/custom/dropDownMenu/components/DropDownMenu";
import { DropdownAction } from "../../../../../models/Common";
import DocumentSendFax from "../containers/DocumentSendFax";

export interface PropsFromDispatch {
  deleteDocumentFile: (blobId: any, userDeleted: string) => void;
  deleteReAuthDocumentFile: (blobId: number, userDeleted: string) => void;
  documentDownload: (blobId: number) => void;
  getDocumentSource: () => void;
  getReferralDocuments: (referralId: number, type: number) => void;
  saveDocumentFile: (payload: any) => void;
  updateDocumentFile: (payload: any, referralId: string) => void;
  resetStates: (actionType: string[]) => void;
  saveInitiateFax: (payload: FormData) => void;
  postGenerateAuthTemplate: (referralId: string) => void;
  getLetterCopyTypeMasterResponse: () => void;
  getServiceRequestNumberMasterResponse: (referralId: number) => void;
  saveAttachLetter: (payload: FormData) => void;
}
export interface PropsFromState {
  documentCreatedBy: CreatedByResponse;
  documentDownloadData: DocumentDownloadResponse;
  documentSource: DocumentSourceResponse;
  documentType: DocumentType[];
  flowType?: string;
  isAuthDetails: boolean;
  refId?: string;
  saveDocumentData: DocumentFileResponse;
  user: UserDetailsPayload;
  tabFormControl?: Control<any, any>;
  tabFormTrigger?: UseFormTrigger<any>;
  generateAuthTemplate: ReAuthReviewBlobDoc[];
  type: number;
  faxSendResponse: InitiateFaxSendingResponse;
  letterCopyTypeMasterData: LetterCopyTypeMaster[];
  serviceRequestNumberMasterData: ServiceRequestNumberMaster[];
  saveAttachLetterData: AttachLetterResponse;
  patientDetailData?: PatientInformationViewResponse;
}

type AllProps = PropsFromState & PropsFromDispatch;

const DocumentsReview: React.FC<AllProps> = ({
  deleteDocumentFile,
  deleteReAuthDocumentFile,
  documentCreatedBy,
  documentDownload,
  documentDownloadData,
  documentSource,
  documentType,
  flowType,
  getDocumentSource,
  getReferralDocuments,
  isAuthDetails,
  refId,
  saveDocumentData,
  saveDocumentFile,
  updateDocumentFile,
  user,
  resetStates,
  tabFormControl,
  tabFormTrigger,
  saveInitiateFax,
  postGenerateAuthTemplate,
  faxSendResponse,
  type,
  generateAuthTemplate,
  getLetterCopyTypeMasterResponse,
  getServiceRequestNumberMasterResponse,
  letterCopyTypeMasterData,
  serviceRequestNumberMasterData,
  saveAttachLetterData,
  saveAttachLetter,
  patientDetailData,
}: AllProps) => {
  const { referralId } = useParams();
  const [files, setFiles] = useState<any>([]);
  const [faxFiles, setFileForFax] = useState<any>([]);
  const [createdBy, setCreatedBy] = useState<UserDetail | null>(null);
  const { loading: loadingDocumentSource, response: documentSourceResponse } =
    documentSource;
  const { response: createdByResponse } = documentCreatedBy;
  const [addDocument, setAddDocument] = useState(false);
  const [showDocument, setShowDocument] = useState(false);
  const [userDetail, setUserDetail] = useState<UserDetail[] | any[]>();
  const [isEdit, setIsEdit] = useState(false);
  const [editDoc, setEditDoc] = useState<any | null>();
  const { response: documentResponse, error: saveDocumentError } =
    saveDocumentData;
  const [documentReviewData, setDocumentReviewData] = useState<BlobDoc[]>([]);
  const { response: documentDownloadResponse, error: documentDownloadError } =
    documentDownloadData;
  const [fileName, setFileName] = useState("");
  const [documentError, setDocumentError] = useState<string>("");
  const [faxBlobId, setFaxBlobId] = useState(0);
  const [addDocForFax, setAddDocForFax] = useState<boolean>(false);
  const [editFaxDocName, setEditFaxDocName] = useState<string>("");
  const [editFaxDocMediaType, setEditFaxDocMediaType] = useState<string>("");
  const maxFileSizeInMegaBytes = documentMaxSize.split("MB")[0];
  const maxFileSizeInBytes = Number(maxFileSizeInMegaBytes) * 1000000;
  const minFileSize = Number(documentMinSize.split("KB")[0]);
  const [selectedFaxBlobIds, setSelectedFaxBlobIds] = useState<BlobData[]>([]);
  const [attachLetter, setAttachLetter] = useState(false);

  const documentSourceData = getList(documentSourceResponse, {
    label: "documentSourceName",
    value: "documentSourceId",
  });
  const documentTypeData = getList(documentType, {
    label: "documentName",
    value: "documentId",
  });
  const serviceRequestNumberData = getList(serviceRequestNumberMasterData, {
    label: "serviceRequestId",
    value: "serviceRequestId",
  });
  const typeData = getList(letterCopyTypeMasterData, {
    label: "typeName",
    value: "typeId",
  });

  const addDocumentDropdown: AddFileFormDropdown[] = [
    {
      inputLabel: DocumentFieldLabel.SOURCE,
      dropdownName: DocumentFieldName.DOCUMENT_SOURCE_ID,
      dropdownHelper: rules.documentSource,
      dropdownList: documentSourceData,
    },
    {
      inputLabel: DocumentFieldLabel.DOC_TYPE,
      dropdownName: DocumentFieldName.DOCUMENT_ID,
      dropdownHelper: rules.documentType,
      dropdownList: documentTypeData,
    },
  ];

  const attachLetterDropdown: AddFileFormDropdown[] = [
    {
      inputLabel: DocumentFieldLabel.SERVICE_REQUEST_NUMBER,
      dropdownName: DocumentFieldName.SERVICE_REQUEST_NUMBER,
      dropdownHelper: rules.serviceRequestNumber,
      dropdownList: serviceRequestNumberData,
    },
    {
      inputLabel: DocumentFieldLabel.TYPE,
      dropdownName: DocumentFieldName.TYPE,
      dropdownHelper: rules.letterCopyType,
      dropdownList: typeData,
    },
  ];

  const defaultValue = {
    dateTimeReceived: null,
    documentSourceId: "0",
    documentId: "0",
    createdBy: createdBy,
    fileName: "",
  };

  const methods = useForm<any>({
    defaultValues: defaultValue,
    mode: "all",
  });

  const {
    setValue,
    control,
    handleSubmit,
    clearErrors,
    unregister,
    getValues,
    watch,
    trigger,
  } = methods;

  const attachLetterDefaultValue = {
    dateTimeReceived: null,
    serviceRequestId: "0",
    typeId: "0",
    fileName: "",
  };

  const attachLetterMethods = useForm<any>({
    defaultValues: attachLetterDefaultValue,
    mode: "all",
  });

  const {
    setValue: attachLetterSetValue,
    control: attachLetterControl,
    handleSubmit: handleAttachLetterSubmit,
    clearErrors: attachLetterClearErrors,
    getValues: attachLetterGetValues,
    watch: attachLetterWatch,
    trigger: attachLetterTrigger,
    unregister: attachLetterUnregister,
    reset: attachLetterReset,
  } = attachLetterMethods;

  const [openFax, setOpenFax] = useState<boolean>(false);
  const faxRef = useRef<any>(null);
  const [documentDropDownButtonOptions, setDocumentDropDownButtonOptions] =
    useState(documentDropDownButtons);

  const DocumentDropdownHandler = (data: DropdownAction) => {
    eval(data.func) as
      | typeof addNewDocument
      | typeof openSendFax
      | typeof handleGenerateAuthTemplate
      | typeof handleAttachLetter;
  };

  useEffect(() => {
    if (openFax || addDocument || attachLetter) {
      const filteredData = documentDropDownButtons.map(
        (dropDownOption: DropdownAction) => {
          if (
            dropDownOption.label === DocumentDropDownButtons.ADD_NEW_DOCUMENT ||
            dropDownOption.label === DocumentDropDownButtons.SEND_FAX ||
            dropDownOption.label === DocumentDropDownButtons.ATTACH_LETTER
          ) {
            return { ...dropDownOption, isDisabled: true };
          }
          return dropDownOption;
        }
      );
      setDocumentDropDownButtonOptions(filteredData);
    } else {
      setDocumentDropDownButtonOptions(documentDropDownButtons);
    }
  }, [openFax, addDocument, attachLetter]);

  useEffect(() => {
    if (!loadingDocumentSource) {
      getDocumentSource();
    }
    return () => {
      resetStates([
        DocumentDownloadDispatchTypes.DOCUMENT_DOWNLOAD_RESET,
        ReferralDocumentFileDispatchTypes.REFERRAL_DOCUMENT_RESET,
      ]);
    };
  }, []);

  useEffect(() => {
    getDocumentList();
    getLetterCopyTypeMasterResponse();
    if (refId) getServiceRequestNumberMasterResponse(Number(refId));
  }, [referralId, refId]);

  useEffect(() => {
    if (
      !isNil(faxSendResponse.response) &&
      faxSendResponse.response.referralBlobId !== -1
    ) {
      setSelectedFaxBlobIds([]);
      getDocumentList();
    }
  }, [faxSendResponse]);

  useEffect(() => {
    if (getValue(generateAuthTemplate, "[0].referralBlobId", 0) > 0)
      setDocumentReviewData(generateAuthTemplate);
  }, [generateAuthTemplate]);

  useEffect(() => {
    if (documentResponse) {
      if (true === documentResponse) {
        //Invoking after delete API call
        setSelectedFaxBlobIds([]);
        getDocumentList();
      } else {
        setDocumentReviewData(documentResponse);
      }
    }
  }, [documentResponse]);

  useEffect(() => {
    if (!isEmpty(saveDocumentError)) {
      setDocumentError(saveDocumentError.data);
    } else {
      setDocumentError("");
      setAddDocument(false);
    }
  }, [saveDocumentError]);

  useEffect(() => {
    if (length(files)) {
      setDocumentError("");
    }
  }, [files]);

  useEffect(() => {
    if (documentReviewData && documentReviewData.length > 0) {
      tabFormTrigger && tabFormTrigger("addedDocuments");
    }
  }, [documentReviewData]);

  const getDocumentList = () => {
    if (referralId || refId) {
      getReferralDocuments(
        referralId ? Number(referralId) : refId ? Number(refId) : 0,
        type
      );
    }
  };

  const handleEditDocument = (row: any) => {
    const userDetails =
      userDetail &&
      length(userDetail) &&
      userDetail.filter((item: UserDetail) => {
        return item.fullName == row.createdBy;
      });
    setAddDocument(true);
    setIsEdit(true);
    setEditDoc(row);
    setFiles([{ name: row.originalDocumentName }]);
    setCreatedBy({
      userId: userDetails && length(userDetails) ? userDetails[0].userId : "",
      fullName: row.createdBy,
    });
    setValue("dateTimeReceived", dayjs(row.dateTimeReceived));
    setValue("documentSourceId", row.documentSourceId);
    setValue("documentId", row.documentId);
    setValue("createdBy", {
      userId: userDetails && length(userDetails) ? userDetails[0].userId : "",
      fullName: row.createdBy,
    });
  };

  const handleScrollView = () => {
    faxRef &&
      faxRef.current &&
      faxRef.current.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
  };

  useEffect(() => {
    if (openFax === true && isAuthDetails) {
      handleScrollView();
    }
  }, [openFax]);

  const handleEditFax = (row: any) => {
    setFaxBlobId(row.blobId);
    setEditFaxDocMediaType(row.mediaType);
    setEditFaxDocName(row.originalDocumentName);
    setFileForFax([]);

    const selectedFileSizeInMb = (
      row.blobContentLength /
      (1024 * 1024)
    ).toFixed(2);

    const blobObj = {
      fileName: row.originalDocumentName,
      blobId: row.blobId,
      blobSize: Number(selectedFileSizeInMb),
    };
    setSelectedFaxBlobIds([...selectedFaxBlobIds, blobObj]);
    setOpenFax(true);
    setAddDocForFax(true);
  };

  useEffect(() => {
    setUserDetail(createdByResponse);
  }, [createdByResponse]);

  useEffect(() => {
    if (files && files.length > 0) {
      clearErrors("fileName");
      attachLetterClearErrors("fileName");
      attachLetterSetValue("fileName", "selected");
      setValue("fileName", "selected");
    } else {
      attachLetterSetValue("fileName", null);
      setValue("fileName", null);
    }
  }, [files]);

  const handleSaveFile = () => {
    const formData = new FormData();
    formData.append(
      "ReferralBlobId",
      editDoc && editDoc.referralBlobId
        ? editDoc.referralBlobId.toString()
        : "0"
    );
    formData.append("ReferralId", referralId ? referralId : refId ? refId : "");
    formData.append("DocumentId", getValues("documentId") || "0");
    formData.append(
      "BlobId",
      editDoc && editDoc.blobId ? editDoc.blobId.toString() : "0"
    );
    formData.append(
      "DateTimeReceived",
      getValues("dateTimeReceived")
        ? getValues("dateTimeReceived").toString()
        : dayjs().toString()
    );
    formData.append("DocumentSourceId", getValues("documentSourceId") || "0");
    formData.append("UserCreated", user.email);
    formData.append(
      "ReferralDocument",
      files && files.length > 0 ? files[0] : {}
    );
    formData.append("UserFullName", user.fullName);
    isEdit
      ? updateDocumentFile(
          formData,
          referralId ? referralId : refId ? refId : ""
        )
      : saveDocumentFile(formData);
    setValue("documentId", "0");
    setValue("documentSourceId", "0");
    setCreatedBy(null);
    setFiles([]);
    setEditDoc(null);
    setIsEdit(false);
  };

  const handleCancelFile = () => {
    setValue("documentId", "0");
    setValue("documentSourceId", "0");
    setCreatedBy(null);
    setFiles([]);
    setIsEdit(false);
    setAddDocument(false);
    unregister([
      "dateTimeReceived",
      "documentSourceId",
      "documentId",
      "createdBy",
      "fileName",
    ]);
  };

  const handleAttachLetterSaveFile = () => {
    const formData = new FormData();
    const patientData = getValue(patientDetailData, "response");
    formData.append("PatientId", getValue(patientData, "patientId"));
    formData.append("ReferralId", referralId ? referralId : refId ? refId : "");
    formData.append("TypeId", attachLetterGetValues("typeId") || "0");
    formData.append("BlobId", "0");
    formData.append(
      "DateTimeReceived",
      attachLetterGetValues("dateTimeReceived")
        ? attachLetterGetValues("dateTimeReceived").toString()
        : dayjs().toString()
    );
    formData.append("UserCreated", user.email);
    formData.append(
      "ServiceRequestId",
      attachLetterGetValues("serviceRequestId") || "0"
    );
    formData.append("BatchUid", uuidv4());
    formData.append(
      "ReferralDocument",
      files && files.length > 0 ? files[0] : {}
    );
    formData.append(
      "PatientLanguage",
      getValue(patientData, "memberInfo.languageNam")
    );
    formData.append("DateMailed", attachLetterGetValues("dateTimeReceived"));
    saveAttachLetter(formData);
    attachLetterSetValue("serviceRequestId", "0");
    attachLetterSetValue("typeId", "0");
    setCreatedBy(null);
    setFiles([]);
    setAttachLetter(false);
  };

  const handleCancelAttachLetter = () => {
    attachLetterSetValue("serviceRequestId", "0");
    attachLetterSetValue("typeId", "0");
    setCreatedBy(null);
    setFiles([]);
    setAttachLetter(false);
    attachLetterUnregister([
      "dateTimeReceived",
      "serviceRequestId",
      "typeId",
      "fileName",
    ]);
  };

  const handleDeleteFile = (blobId: any) => {
    const userName = getValue(user, "email", "");
    if (flowType && flowType == "reAuth") {
      deleteReAuthDocumentFile(blobId, userName);
    } else {
      deleteDocumentFile(blobId, userName);
    }
  };

  const downloadFile = (blobId: number, fileName: string) => {
    documentDownload(blobId);
    setFileName(fileName);
  };

  useEffect(() => {
    if (documentDownloadResponse && documentDownloadResponse.blob) {
      const blob = new Blob([documentDownloadResponse.blob], {
        type: documentDownloadResponse.headers["content-type"],
      });
      FileSaver.saveAs(blob, fileName);
      setFileName("");
    } else if (
      documentDownloadError &&
      documentDownloadError.error &&
      Object.keys(documentDownloadError.error).length == 0
    ) {
      SnackbarUtils.error(
        ReferralResponseMessages(MessageType.DOCUMENT_DOWNLOAD_ERROR)
      );
    }
  }, [documentDownloadResponse, documentDownloadError]);

  const addNewDocument = () => {
    setAddDocument(true);
    setValue("documentSourceId", undefined);
    setValue("documentId", undefined);
    setCreatedBy(null);
    setSelectedFaxBlobIds([]);
    setValue("dateTimeReceived", null);
    setValue("documentSourceId", null);
    setValue("documentId", null);
    setValue("createdBy", null);
    setValue("fileName", null);
  };

  const handleAttachLetter = () => {
    setAttachLetter(true);
    setSelectedFaxBlobIds([]);
    attachLetterReset({
      dateTimeReceived: null,
      serviceRequestId: null,
      typeId: null,
      fileName: null,
    });
  };

  const openSendFax = () => {
    if (selectedFaxBlobIds.length > 0) {
      setAddDocForFax(true);
      setFaxBlobId(-1);
    } else {
      setFaxBlobId(0);
      setAddDocForFax(false);
    }
    setOpenFax(true);
  };

  const handleGenerateAuthTemplate = () => {
    if (refId) postGenerateAuthTemplate(refId);
  };

  const handleRefreshFaxStatus = () => {
    getDocumentList();
  };

  const selectFaxBlobId = (
    blobId: number,
    docName: string,
    selectedBlobLength: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked === true) {
      const checkedFileSizeInMb = selectedFaxBlobIds.map(
        (data) => data.blobSize
      );
      const selectedFileSizeInMb = (selectedBlobLength / (1024 * 1024)).toFixed(
        2
      );

      const sumFileSize = checkedFileSizeInMb.reduce(
        (accumulator: number, currentValue: number) =>
          accumulator + currentValue,
        0
      );

      if (
        Math.ceil(Number(sumFileSize) + Number(selectedFileSizeInMb)) <
        Number(documentFaxMaxSize)
      ) {
        const blobObj = {
          fileName: docName,
          blobId: blobId,
          blobSize: Number(selectedFileSizeInMb),
        };
        setSelectedFaxBlobIds([...selectedFaxBlobIds, blobObj]);
      } else {
        SnackbarUtils.warning(ResponseMessages(MessageType.SEND_FAX_MAX_SIZE));
      }
    } else {
      setSelectedFaxBlobIds(
        selectedFaxBlobIds.filter((id) => id.blobId !== blobId)
      );
    }
  };

  const headerTableCell = (
    value: string | number,
    width: string,
    displayIcon?: boolean,
    isDocNo?: boolean,
    key?: number
  ) => {
    return (
      <>
        <TableCell width={width} key={key}>
          <Box sx={HeaderTableCell(isDocNo)}>
            <Typography
              variant="subtitle2"
              fontWeight={fontWeight.Weight[3]}
              color={colors.black[14]}
            >
              {value}
            </Typography>
            {displayIcon && (
              <FaSyncAlt
                size={"0.8rem"}
                style={RefreshIconStyle}
                onClick={() => handleRefreshFaxStatus()}
              />
            )}
          </Box>
        </TableCell>
      </>
    );
  };

  const faxReadyToDownload = (blobId: number, documentSourceName: string) => {
    return documentSourceName === Source.FAX && blobId === 0;
  };

  useEffect(() => {
    if (saveAttachLetterData.response) {
      setDocumentReviewData(saveAttachLetterData.response);
    }
  }, [saveAttachLetterData.response]);

  return (
    <Grid container pb={"2rem"}>
      <Grid item xs={12}>
        <Grid container sx={Header}>
          <Grid item xs={8} sx={flexAlignCentre}>
            {isAuthDetails && showDocument ? (
              <FaChevronCircleDownIcon
                onClick={() => setShowDocument(false)}
                sx={ShowIcon}
              />
            ) : isAuthDetails && !showDocument ? (
              <FaChevronCircleUpIcon
                onClick={() => setShowDocument(true)}
                sx={ShowIcon}
              />
            ) : (
              ""
            )}
            <FaRegFileAlt
              size={"1.2rem"}
              color={colors.fonts[18]}
              style={isAuthDetails ? {} : FileIcon}
            />
            <Typography
              variant={isAuthDetails ? "subtitle1" : "subtitle2"}
              fontWeight={fontWeight.Weight[5]}
              color={colors.fonts[20]}
              sx={DocumentTitle}
            >
              DOCUMENT REVIEW:
            </Typography>
            {tabFormControl && (
              <Typography
                variant="subtitle1"
                fontWeight={fontWeight.Weight[4]}
                color={colors.red[100]}
                mt={"0.2rem"}
              >
                *
              </Typography>
            )}
          </Grid>
          {isAuthDetails ? (
            <Grid item xs={4} sx={addedDocumentField}>
              <DropDownMenu
                label="DOCUMENT ACTIONS"
                documentDropDownButtons={documentDropDownButtonOptions}
                onSelect={DocumentDropdownHandler}
              />
            </Grid>
          ) : (
            <Permission
              controlId={`${PatientRecordsAccess.DOCUMENT_CREATE}|${PatientRecordsAccess.DOCUMENT_READ}|`}
              passThrough
            >
              {(isDisabled: boolean) => (
                <Button
                  variant="contained"
                  onClick={() => addNewDocument()}
                  disabled={
                    addDocument || openFax || attachLetter || isDisabled
                  }
                >
                  ADD NEW DOCUMENT
                </Button>
              )}
            </Permission>
          )}
        </Grid>
      </Grid>
      <Divider
        light
        sx={isAuthDetails ? HorizontalDivider : TabHorizontalDivider}
      />
      {!showDocument && (
        <>
          <Grid item xs={12} sx={AddDocumentSection(isAuthDetails)}>
            <Box>
              <Typography
                variant="h6"
                fontWeight={fontWeight.Weight[6]}
                color={colors.fonts[20]}
              >
                ADD / MODIFY:
              </Typography>
            </Box>
            <Box pt={"2rem"}>
              <TableContainer sx={TableWidth}>
                <Table aria-label="collapsible table" sx={BodyTable}>
                  <TableHead sx={HeaderItem}>
                    <TableRow>
                      {isAuthDetails && headerTableCell("", "2%")}
                      {HeaderLabelDocumentReview.map(
                        (row: any, index: number) => {
                          return headerTableCell(
                            row.label,
                            row.width,
                            row.displayIcon,
                            row.isDocNo,
                            index
                          );
                        }
                      )}

                      {isAuthDetails &&
                        headerTableCell("FAX STATUS", "12%", true)}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {documentReviewData &&
                      length(documentReviewData) &&
                      documentReviewData.map((row: any) => {
                        return (
                          <TableRow key={row.blobId}>
                            {isAuthDetails ? (
                              row.documentSourceName !== "Fax" &&
                              checkSendFaxDocType(row.mediaType) === true ? (
                                <TableCell>
                                  <Checkbox
                                    handleChange={(e) =>
                                      selectFaxBlobId(
                                        row.blobId,
                                        row.originalDocumentName,
                                        row.blobContentLength,
                                        e
                                      )
                                    }
                                    checked={selectedFaxBlobIds.some(
                                      (data) => data.blobId === row.blobId
                                    )}
                                    disabled={openFax || addDocument}
                                  ></Checkbox>
                                </TableCell>
                              ) : (
                                <TableCell></TableCell>
                              )
                            ) : null}
                            {documentTableCell(row.sequenceNo, false, true)}
                            <TableCell sx={DocumentDetailsTableCell}>
                              <Box sx={flexAlignCentre}>
                                <Box sx={DocumentTypeIcon}>
                                  {getDocTypeIcon(
                                    extractDocType(row.mediaType),
                                    faxReadyToDownload(
                                      row.blobId,
                                      row.documentSourceName
                                    )
                                      ? colors.grey[1]
                                      : undefined
                                  )}
                                </Box>
                                <Typography
                                  variant="h5"
                                  fontWeight={fontWeight.Weight[6]}
                                  color={colors.primary.main}
                                  pt={"0.3rem"}
                                >
                                  <Link
                                    variant="h5"
                                    fontWeight={fontWeight.Weight[4]}
                                    color={colors.fonts[18]}
                                    onClick={() =>
                                      downloadFile(
                                        row.blobId,
                                        row.originalDocumentName
                                      )
                                    }
                                    sx={
                                      faxReadyToDownload(
                                        row.blobId,
                                        row.documentSourceName
                                      )
                                        ? DisabledDocumentLink
                                        : DocumentNameUnderline
                                    }
                                  >
                                    <Tooltip
                                      title={
                                        <Typography
                                          variant="subtitle2"
                                          fontWeight={fontWeight.Weight[3]}
                                          lineHeight="1rem"
                                        >
                                          {row.originalDocumentName}
                                        </Typography>
                                      }
                                      arrow
                                    >
                                      <Typography
                                        variant="subtitle1"
                                        fontWeight={fontWeight.Weight[5]}
                                        color={colors.fonts[18]}
                                        sx={
                                          faxReadyToDownload(
                                            row.blobId,
                                            row.documentSourceName
                                          )
                                            ? [
                                                DocumentName,
                                                DisabledDocumentLink,
                                              ]
                                            : DocumentName
                                        }
                                      >
                                        {row.originalDocumentName}
                                      </Typography>
                                    </Tooltip>
                                  </Link>
                                </Typography>
                              </Box>
                            </TableCell>
                            {documentTableCell(
                              row.showDateTime
                                ? formatDate(
                                    row.dateTimeReceived,
                                    DateFormats.MM_DD_YYYY_hh_mm_ss_a
                                  )
                                : "",
                              true
                            )}
                            {documentTableCell(row.documentSourceName)}
                            {documentTableCell(row.documentTypeName)}
                            {documentTableCell(row.userFullName)}
                            <TableCell sx={ActionSection}>
                              {!row.isLetterDocument ? (
                                <>
                                  {isAuthDetails &&
                                  indexOf(
                                    [Source.INBOUND, Source.FAX],
                                    row.documentSourceName
                                  ) !== -1 ? (
                                    <>
                                      {row.sendFax && (
                                        <FaFax
                                          size={"1rem"}
                                          color={colors.fonts[18]}
                                          onClick={() => handleEditFax(row)}
                                        />
                                      )}
                                      {faxReadyToDownload(
                                        row.blobId,
                                        row.documentSourceName
                                      ) ? (
                                        <FaDownload
                                          size={"1rem"}
                                          style={DisabledDownloadIcon}
                                        />
                                      ) : (
                                        <FaDownload
                                          size={"1rem"}
                                          color={colors.fonts[18]}
                                          onClick={() =>
                                            downloadFile(
                                              row.blobId,
                                              row.originalDocumentName
                                            )
                                          }
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <Permission
                                      controlId={`|${PatientRecordsAccess.DOCUMENT_READ}|${PatientRecordsAccess.DOCUMENT_UPDATE}`}
                                      passThrough
                                    >
                                      {(isDisabled: boolean) => (
                                        <>
                                          <FaEdit
                                            size={"1.1rem"}
                                            color={colors.fonts[18]}
                                            style={
                                              isDisabled ? IconDisabled : {}
                                            }
                                            onClick={() =>
                                              handleEditDocument(row)
                                            }
                                          />
                                          <FaRegTrashAlt
                                            size={"1rem"}
                                            color={accent.accentOrange[100]}
                                            style={
                                              isDisabled ? IconDisabled : {}
                                            }
                                            onClick={() =>
                                              handleDeleteFile(row.blobId)
                                            }
                                          />
                                        </>
                                      )}
                                    </Permission>
                                  )}
                                </>
                              ) : (
                                <FaDownload
                                  size={"1rem"}
                                  color={colors.fonts[18]}
                                  onClick={() =>
                                    downloadFile(
                                      row.blobId,
                                      row.originalDocumentName
                                    )
                                  }
                                />
                              )}
                            </TableCell>
                            {isAuthDetails &&
                              documentTableCell(
                                row.faxStatus === "None"
                                  ? ""
                                  : FaxSentStatus[
                                      row.faxStatus as keyof typeof FaxSentStatus
                                    ]
                              )}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </>
      )}
      {addDocument && (
        <AddFileForm
          control={control}
          files={files}
          setFiles={setFiles}
          type={DocumentTabType.DOCUMENT}
          maxFileSizeInBytes={maxFileSizeInBytes}
          maxFileSizeInMegaBytes={maxFileSizeInMegaBytes}
          minFileSize={minFileSize}
          allowedFiles={DocumentComponentTitle.DOCUMENT_ALLOWED_FILE}
          title={DocumentComponentTitle.ADD_DOCUMENTATION}
          setValue={setValue}
          getValues={getValues}
          watch={watch}
          trigger={trigger}
          clearErrors={clearErrors}
          dateLabel={DocumentFieldLabel.DATE_RECEIVED}
          timeLabel={DocumentFieldLabel.TIME_RECEIVED}
          addFileFormDropdownList={addDocumentDropdown}
          handleCancelFile={handleCancelFile}
          handleSave={handleSubmit(handleSaveFile)}
          documentError={documentError}
        />
      )}
      {openFax && (
        <Grid item xs={12} sx={SendFaxGrid}>
          <DocumentSendFax
            saveInitiateFax={saveInitiateFax}
            setOpenFax={setOpenFax}
            openFax={openFax}
            user={user}
            resetStates={resetStates}
            faxFiles={faxFiles}
            setFileForFax={setFileForFax}
            isEdit={isEdit}
            faxSendResponse={faxSendResponse}
            faxBlobId={faxBlobId}
            setAddDocForFax={setAddDocForFax}
            addDocForFax={addDocForFax}
            setEditFaxDocName={setEditFaxDocName}
            editFaxDocName={editFaxDocName}
            editFaxDocMediaType={editFaxDocMediaType}
            setEditFaxDocMediaType={setEditFaxDocMediaType}
            faxRef={faxRef}
            setSelectedFaxBlobIds={setSelectedFaxBlobIds}
            selectedFaxBlobIds={selectedFaxBlobIds}
          />
        </Grid>
      )}
      {attachLetter && (
        <AddFileForm
          control={attachLetterControl}
          files={files}
          setFiles={setFiles}
          type={DocumentTabType.ATTACH_LETTER}
          maxFileSizeInBytes={maxFileSizeInBytes}
          maxFileSizeInMegaBytes={maxFileSizeInMegaBytes}
          minFileSize={minFileSize}
          title={DocumentComponentTitle.ATTACH_LETTER}
          allowedFiles={DocumentComponentTitle.ATTACH_LETTER_ALLOWED_FILE}
          setValue={attachLetterSetValue}
          getValues={attachLetterGetValues}
          watch={attachLetterWatch}
          trigger={attachLetterTrigger}
          clearErrors={attachLetterClearErrors}
          dateLabel={DocumentFieldLabel.MAIL_DATE}
          timeLabel={DocumentFieldLabel.TIME}
          addFileFormDropdownList={attachLetterDropdown}
          handleCancelFile={handleCancelAttachLetter}
          handleSave={handleAttachLetterSubmit(handleAttachLetterSaveFile)}
        />
      )}
    </Grid>
  );
};

const documentTableCell = (
  value: string | number,
  date?: boolean,
  isDocNo?: boolean
) => {
  return (
    <>
      <TableCell sx={DocumentDetailsTableCell}>
        <Typography
          variant="subtitle1"
          fontWeight={date ? fontWeight.Weight[5] : fontWeight.Weight[6]}
          color={colors.fonts[4]}
          sx={DocumentData(isDocNo)}
        >
          {value}
        </Typography>
      </TableCell>
    </>
  );
};

export default DocumentsReview;
