import {
  useEffect,
  useState,
  useRef,
  MutableRefObject,
  Dispatch,
  SetStateAction,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash";

import { colors } from "../../../../../styles/colors";
import { displayFlex } from "../../../../../styles/mui/styles/display";
import {
  formatDate,
  getLatestDate,
  isAfter11_59PM,
} from "../../../../../utils/DateTime";
import {
  getServiceRequestNumber,
  getValue,
  length,
  serviceStatusColor,
  urgencyStatusColor,
} from "../../../../../utils";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  Action,
  AlignCenter,
  ButtonGrid,
  ContainerStyle,
  DisciplineTablecell,
  FaChevronCircleDownIcon,
  FaChevronCircleUpIcon,
  Gridbody,
  GridTableContent,
  HeaderTableCell,
  HeaderTitle,
  HorizontalDivider,
  PatientTableCell,
  ReviewButton,
  ServiceData,
  TableCellSticky,
  TableCellStickyHeader,
  TableCellStickyHeaderLeftSecond,
  TableCellStickyLeftSecond,
  TableHeight,
  TableMain,
  TableMainHead,
  TableMainHeadRow,
  TableNameDetails,
  VerticalDivider,
  ViewBoxStyle,
  VisitTableCell,
} from "../styles/Disciplines";
import {
  Discipline,
  DisciplineNoteSearch,
  ReferralDiscipline,
  UpdatedReferralDiscipline,
} from "../../../../../models/Disciplines";
import {
  ReceivedMethodMasterResponse,
  UrgencyStatusMasterResponse,
} from "../../../../../models/Api/Patient";
import DisciplineServiceDetails from "./DisciplineServiceDetails";
import {
  DisciplineRequestorResponse,
  DisciplineTemplateResponse,
  DisciplineTypeResponse,
  ReferralServiceStatusResponse,
  ServiceStatusReasonResponse,
} from "../../../../../models/Api/Master";
import { NO_RECORD_FOUND } from "../../../../../constants/ToastMessage";
import { UpdateDisciplineResponse } from "../../../../../models/Api/Discipline";
import {
  AuthType,
  DisciplineHeaderLabel,
  disciplineList,
  PatientList,
  ServiceStatus,
} from "../../../../../constants/AllPatientRecord";
import { DateFormats } from "../../../../../constants/Date";
import { RationaleTemplatePayload } from "../../../../../models/Master";
import {
  AuthorizationDetailTabsResponse,
  ReAuthTimelineResponse,
} from "../../../../../models/Api/Authorization";
import {
  DisciplineActionDispatchTypes,
  DisciplineRecord,
  getbase64Value,
  UpdateDisciplineActionDispatchTypes,
} from "../../../../../constants/Discipline";
import { PatientInformationViewResponse } from "../../../../../models/Api/PatientInformation";
import {
  DisciplineNoteResponse,
  GetDisciplineLevelNote,
  SaveDisciplineLevelNote,
  UpdateDisciplineLevelNote,
} from "../../../../../models/Api/Notes";
import {
  DisciplineNoteActionDispatchTypes,
  GetAdminNotesByVersionIdActionDispatchTypes,
  GetDisciplineLevelNotesActionDispatchTypes,
  SaveDisciplineLevelNotesActionDispatchTypes,
  SaveReferralLevelNotesActionDispatchTypes,
} from "../../../../../constants/Notes";
import { DisciplineLevelNote } from "../../../../../models/Notes";
import { useDraggable } from "react-use-draggable-scroll";
import Permission from "../../../../Permission";
import { AuthDetailsAccess } from "../../../../../constants/Permission";
import { PatientRecordsModule, ModuleLinks } from "../../../../AllRoutes";
import { PutAuthorizationDetailTabActionDispatchTypes } from "../../../../../constants/Authorization";
import { LoggedInUserResponse } from "../../../../../models/Api/User";
import { DropdownOption } from "../../../../../components/formComponents/constant/FormComponents";
import { UserListWithFilterPayload, User } from "../../../../../models/User";
import { AssignedStaff } from "../../../../../models/Patient";
import { MyPatientResponse } from "../../../../../models/Api/AllPatientRecord";

export interface PropsFromDispatch {
  getDisciplineRequestorResp: () => void;
  getDisciplinesData: (referralId: number) => void;
  getDisciplineTypeResp: () => void;
  getReAuthTimelineData: (referralId: string) => void;
  getReferralDisciplinesData: (referralDisciplineId: number) => void;
  getReferralServicestatusResp: (value: number) => void;
  getServiceStatusReasonResp: (serviceId: number) => void;
  getUrgencyMasterResp: () => void;
  getUpdateSurveyDetails: (data: any) => void;
  updateDisciplinesData: (
    authDisciplineId: number,
    payload: UpdatedReferralDiscipline
  ) => void;
  updatePhysicianReviewData: (
    authDisciplineId: number,
    payload: ReferralDiscipline
  ) => void;
  getDisciplineTemplateResp: (payload: RationaleTemplatePayload) => void;
  resetStates: (actionType: string[]) => void;
  getDisciplineNoteList: (payload: DisciplineNoteSearch) => void;
  getDisciplineLevelNotes: (disciplineNoteId: number) => void;
  updateDisciplineLevelNoteData: (
    disciplineNoteId: number,
    payload: DisciplineLevelNote
  ) => void;
  saveDisciplineLevelNotesData: (payload: DisciplineLevelNote) => void;
  setRenderReferralNotes?: Dispatch<SetStateAction<boolean>>;
  setServiceDetails: Dispatch<SetStateAction<boolean>>;
  serviceDetails: boolean;
  getStaffList: (payload: UserListWithFilterPayload) => void;
  updateAssignStaff: (
    assignedToStaffId: number,
    payload: AssignedStaff
  ) => void;
  getReceivedMethodDetails: (serviceRequestTypeId: number) => void;
}
export interface PropsFromState {
  disciplineRequestorStatus: DisciplineRequestorResponse;
  disciplines: Discipline[];
  disciplineTemplateStatus: DisciplineTemplateResponse;
  disciplineTypeStatus: DisciplineTypeResponse;
  reAuthTimeline: ReAuthTimelineResponse;
  referralDisciplines: ReferralDiscipline;
  referralServiceStatus: ReferralServiceStatusResponse;
  serviceStatusReasonStatus: ServiceStatusReasonResponse;
  updateDiscipline: UpdateDisciplineResponse;
  urgencyStatus: UrgencyStatusMasterResponse;
  updatedDisciplineResp: UpdateDisciplineResponse;
  disciplineServiceDetailsError: any;
  priorAuthReferralSourceFacilityList: DropdownOption[];
  reAuthReferralSourceFacilityList: DropdownOption[];
  patientDetailData: PatientInformationViewResponse;
  patientData: PatientInformationViewResponse;
  disciplineNoteListData: DisciplineNoteResponse;
  GetDisciplineLevelNotesData: GetDisciplineLevelNote;
  saveDisciplineLevelNotesDataResp: SaveDisciplineLevelNote;
  updateDisciplineLevelNoteDataresp: UpdateDisciplineLevelNote;
  authorizationDetailTabsData: AuthorizationDetailTabsResponse;
  userDetails: LoggedInUserResponse;
  userList: User[];
  receivedMethodData: ReceivedMethodMasterResponse;
  putMyPatientListData: MyPatientResponse;
}
type AllProps = PropsFromState & PropsFromDispatch;

const Disciplines: React.FC<AllProps> = ({
  disciplineRequestorStatus,
  disciplines,
  disciplineTemplateStatus,
  disciplineTypeStatus,
  patientDetailData,
  getDisciplineRequestorResp,
  updatedDisciplineResp,
  disciplineServiceDetailsError,
  getDisciplinesData,
  getDisciplineTemplateResp,
  getDisciplineTypeResp,
  getReAuthTimelineData,
  getReferralDisciplinesData,
  getReferralServicestatusResp,
  getServiceStatusReasonResp,
  getUrgencyMasterResp,
  getUpdateSurveyDetails,
  referralDisciplines,
  referralServiceStatus,
  serviceStatusReasonStatus,
  updateDisciplinesData,
  updatePhysicianReviewData,
  urgencyStatus,
  resetStates,
  priorAuthReferralSourceFacilityList,
  reAuthReferralSourceFacilityList,
  disciplineNoteListData,
  getDisciplineNoteList,
  getDisciplineLevelNotes,
  saveDisciplineLevelNotesData,
  updateDisciplineLevelNoteData,
  GetDisciplineLevelNotesData,
  saveDisciplineLevelNotesDataResp,
  updateDisciplineLevelNoteDataresp,
  setRenderReferralNotes,
  setServiceDetails,
  serviceDetails,
  patientData,
  authorizationDetailTabsData,
  userDetails,
  userList,
  getStaffList,
  updateAssignStaff,
  receivedMethodData,
  getReceivedMethodDetails,
  putMyPatientListData,
}: AllProps) => {
  const [disciplinesData, setDisciplinesData] = useState<Discipline[]>([]);
  const [showDiscipline, setShowDiscipline] = useState(false);
  const [disciplineRecord, setDisciplineRecord] =
    useState<Discipline>(DisciplineRecord);
  const [disableAddService, setDisableAddService] = useState<boolean>(false);
  const disciplineRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<any>(null);
  const containerRef = useRef<any>() as MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(containerRef, {
    isMounted: containerRef.current && !showDiscipline ? true : false,
  });
  const [disciplineDateTimeList, setDisciplineDateTimeList] =
    useState<string[]>();
  const [isPendingDiscipline, setIsPendingDiscipline] =
    useState<boolean>(false);
  const { authNo } = useParams();
  const handleSelfRef = () => {
    if (headerRef.current != null) {
      const headerPosition = headerRef && headerRef.current.offsetTop;
      window.scrollTo({
        top: headerPosition,
        behavior: "smooth",
      });
    }
  };

  const checkLengthOftheDisciplines = (disciplines: Discipline[]) => {
    const disciplineWithoutDateTime: Discipline[] = [];

    disciplines.forEach((obj: Discipline) => {
      if (
        isEmpty(obj.decisionDateTime) &&
        obj.serviceStatus !== ServiceStatus.PENDING
      ) {
        disciplineWithoutDateTime.push(obj);
      }
    });
    return (
      disciplineWithoutDateTime.length !==
      disciplines.filter(
        (item: Discipline) => item.serviceRequestTypeName != AuthType.RE_AUTH
      ).length
    );
  };

  const checkDisciplineStatus = (disciplines: Discipline[]) => {
    const disciplineDateTimeListTemp: string[] = [];
    const hasReAuth = disciplines.some(
      (request) => request.serviceRequestTypeName === AuthType.RE_AUTH
    );

    if (hasReAuth === true) {
      setDisableAddService(true);
    } else {
      if (checkLengthOftheDisciplines(disciplines)) {
        disciplines.forEach((obj: Discipline) => {
          if (
            !isEmpty(obj.decisionDateTime) &&
            obj.serviceStatus !== ServiceStatus.PENDING &&
            obj.serviceRequestTypeName !== AuthType.RE_AUTH
          ) {
            disciplineDateTimeListTemp.push(obj.decisionDateTime);
          }
          if (
            obj.serviceStatus === ServiceStatus.PENDING &&
            obj.serviceRequestTypeName !== AuthType.RE_AUTH
          ) {
            setIsPendingDiscipline(true);
            setDisableAddService(false);
          }
        });
        setDisciplineDateTimeList(disciplineDateTimeListTemp);
      } else {
        setDisableAddService(true);
      }
    }
  };

  useEffect(() => {
    if (
      isPendingDiscipline === false &&
      !isEmpty(getLatestDate(disciplineDateTimeList)) &&
      isAfter11_59PM(getLatestDate(disciplineDateTimeList))
    ) {
      setDisableAddService(true);
    } else {
      setDisableAddService(false);
    }
  }, [disciplineDateTimeList]);

  useEffect(() => {
    if (disciplines) {
      if (length(disciplines)) {
        checkDisciplineStatus(disciplines);
      }
      setDisciplinesData(disciplines);
    } else {
      setDisciplinesData([]);
    }
  }, [disciplines]);

  useEffect(() => {
    getDisciplinesData(Number(authNo));
  }, []);

  useEffect(() => {
    return () => {
      resetStates([DisciplineActionDispatchTypes.DISCIPLINE_RESET]);
    };
  }, []);

  const handleReview = (discipline: Discipline) => {
    resetStates([
      UpdateDisciplineActionDispatchTypes.UPDATE_DISCIPLINE_RESET,
      GetDisciplineLevelNotesActionDispatchTypes.GET_DISCIPLINE_LEVEL_NOTES_RESET,
      GetAdminNotesByVersionIdActionDispatchTypes.GET_NOTES_RESET,
      SaveReferralLevelNotesActionDispatchTypes.SAVE_REFERRAL_NOTES_RESET,
      SaveDisciplineLevelNotesActionDispatchTypes.SAVE_DISCIPLINE_NOTES_RESET,
      DisciplineNoteActionDispatchTypes.GET_DISCIPLINE_NOTES_RESET,
      PutAuthorizationDetailTabActionDispatchTypes.PUT_AUTHORIZATION_DETAIL_TAB_RESET,
    ]);
    setServiceDetails(true);
    setDisciplineRecord(discipline);
    setRenderReferralNotes && setRenderReferralNotes(false);
  };

  const validation = () => {
    return (
      <TableRow>
        <TableCell colSpan={14}>
          <Box display="flex" justifyContent={"center"}>
            <Typography
              variant="subtitle2"
              fontWeight={fontWeight.Weight[4]}
              sx={DisciplineTablecell}
            >
              {NO_RECORD_FOUND}
            </Typography>
          </Box>
        </TableCell>
      </TableRow>
    );
  };

  const navigate = useNavigate();

  const handleAddService = () => {
    if (getValue(patientData, "response.patientId", "")) {
      navigate(
        ModuleLinks(PatientRecordsModule.REFERRAL_INTAKE, {
          referralId: Number(getValue(patientData, "response.referralId")),
          patientId: Number(getValue(patientData, "response.patientId")),
          additionalParams: getbase64Value(),
        })
      );
    }
  };

  const getDisableAddService = (isComplete: boolean) => {
    if (!isComplete) {
      if (disciplinesData && getValue(disciplinesData, "[0].referralId") > 0) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  };

  return (
    <>
      <Grid container sx={Gridbody} ref={headerRef}>
        <Grid item xs={12} sx={displayFlex}>
          {showDiscipline ? (
            <FaChevronCircleDownIcon onClick={() => setShowDiscipline(false)} />
          ) : !showDiscipline ? (
            <FaChevronCircleUpIcon onClick={() => setShowDiscipline(true)} />
          ) : (
            ""
          )}
          <Typography
            variant="subtitle1"
            color={colors.fonts[20]}
            fontWeight={fontWeight.Weight[5]}
            sx={HeaderTitle}
          >
            DISCIPLINES:
          </Typography>
          <Divider orientation="vertical" flexItem sx={VerticalDivider} />
          <Typography
            variant="subtitle2"
            fontWeight={fontWeight.Weight[2]}
            color={colors.black[2]}
            pt={"0.3rem"}
          >
            Below is the summary for the all discipline requests.
          </Typography>
          <Grid item xs sx={ButtonGrid}>
            <Button
              variant="contained"
              disabled={getDisableAddService(disableAddService)}
              onClick={() => handleAddService()}
            >
              Add Service
            </Button>
          </Grid>
        </Grid>
        <Divider light sx={HorizontalDivider} />
        <Grid
          item
          xs={12}
          sx={[
            GridTableContent,
            !showDiscipline && !serviceDetails ? {} : { display: "none" },
          ]}
          ref={disciplineRef}
        >
          <TableContainer
            sx={ContainerStyle}
            {...events}
            ref={containerRef}
            component={Paper}
          >
            <TableMain>
              <TableMainHead>
                <TableMainHeadRow>
                  {DisciplineHeaderLabel.map((tablecell: any, index: any) =>
                    index !== 0 &&
                    index !== DisciplineHeaderLabel.length - 1 ? (
                      <TableCell key={index} sx={HeaderTableCell}>
                        <Box sx={tablecell.style?.cell}>
                          <Box sx={AlignCenter}>
                            <Typography
                              variant="body1"
                              fontWeight={fontWeight.Weight[3]}
                              color={colors.black[16]}
                            >
                              {tablecell.label}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                    ) : index !== 0 ? (
                      <TableCellStickyHeader key={index}>
                        <Box sx={tablecell.style?.cell}>
                          <Box sx={AlignCenter}>
                            <Typography
                              variant="body1"
                              fontWeight={fontWeight.Weight[3]}
                              color={colors.black[16]}
                              sx={Action}
                            >
                              {tablecell.label}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCellStickyHeader>
                    ) : (
                      <TableCellStickyHeaderLeftSecond key={index}>
                        <Box sx={tablecell.style?.cell}>
                          <Box sx={AlignCenter}>
                            <Typography
                              variant="body1"
                              fontWeight={fontWeight.Weight[3]}
                              color={colors.black[16]}
                              sx={ServiceData}
                            >
                              {tablecell.label}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCellStickyHeaderLeftSecond>
                    )
                  )}
                </TableMainHeadRow>
              </TableMainHead>
              <TableBody>
                {length(disciplinesData) && disciplinesData[0].referralId !== -1
                  ? disciplinesData.map((row: any, rowIndex: number) => {
                      return (
                        <TableRow key={rowIndex} sx={TableHeight}>
                          {disciplineList.map(
                            (data: PatientList, index: number) => {
                              if (data.value === disciplineList[0].value) {
                                return (
                                  <TableCellStickyLeftSecond
                                    sx={TableNameDetails(
                                      disciplinesData.length - 1 === rowIndex
                                    )}
                                    key={index}
                                  >
                                    <Typography
                                      variant="h6"
                                      fontWeight={data.fontWeight}
                                      color={colors.fonts[18]}
                                      ml={"0.5rem"}
                                    >
                                      {getServiceRequestNumber(row[data.value])}
                                    </Typography>
                                  </TableCellStickyLeftSecond>
                                );
                              } else if (
                                data.value === disciplineList[1].value
                              ) {
                                return (
                                  <TableCell
                                    sx={TableNameDetails(
                                      disciplinesData.length - 1 === rowIndex
                                    )}
                                    key={index}
                                  >
                                    <Typography
                                      variant="h6"
                                      fontWeight={data.fontWeight}
                                      color={colors.fonts[18]}
                                    >
                                      {row[data.value]}
                                    </Typography>
                                  </TableCell>
                                );
                              } else if (
                                data.value === disciplineList[2].value
                              ) {
                                return (
                                  <TableCell
                                    key={index}
                                    sx={PatientTableCell(
                                      disciplinesData.length - 1 === rowIndex
                                    )}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      fontWeight={data.fontWeight}
                                      color={data.fontWeight}
                                    >
                                      {formatDate(
                                        row[data.value],
                                        DateFormats.MM_DD_YYYY_comma_hh_mm_ss_a
                                      )}
                                    </Typography>
                                  </TableCell>
                                );
                              }
                              return (
                                <TableCell
                                  key={index}
                                  sx={
                                    data.value === disciplineList[8].value ||
                                    data.value === disciplineList[9].value ||
                                    data.value === disciplineList[10].value
                                      ? VisitTableCell(
                                          disciplinesData.length - 1 ===
                                            rowIndex
                                        )
                                      : PatientTableCell(
                                          disciplinesData.length - 1 ===
                                            rowIndex
                                        )
                                  }
                                >
                                  <Typography
                                    variant="subtitle2"
                                    fontWeight={data.fontWeight}
                                    color={
                                      data.value === disciplineList[11].value
                                        ? serviceStatusColor(row[data.value])
                                        : data.value === disciplineList[4].value
                                        ? urgencyStatusColor(row[data.value])
                                        : data.fontWeight
                                    }
                                  >
                                    {data.value === disciplineList[6].value ||
                                    data.value === disciplineList[7].value
                                      ? formatDate(
                                          row[data.value],
                                          DateFormats.MM_DD_YYYY
                                        )
                                      : row[data.value]}
                                  </Typography>
                                </TableCell>
                              );
                            }
                          )}
                          <TableCellSticky
                            sx={PatientTableCell(
                              disciplinesData.length - 1 === rowIndex
                            )}
                          >
                            <Box sx={ViewBoxStyle}>
                              <Permission
                                controlId={`|${AuthDetailsAccess.DISCIPLINE_AUTH_DETAILS_READ}|${AuthDetailsAccess.DISCIPLINE_AUTH_DETAILS_UPDATE}`}
                                passThrough
                              >
                                {(isDisabled: boolean) => (
                                  <Button
                                    variant="contained"
                                    onClick={() => handleReview(row)}
                                    sx={ReviewButton}
                                    disabled={isDisabled}
                                  >
                                    REVIEW
                                  </Button>
                                )}
                              </Permission>
                            </Box>
                          </TableCellSticky>
                        </TableRow>
                      );
                    })
                  : validation()}
              </TableBody>
            </TableMain>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          {serviceDetails && !showDiscipline && (
            <DisciplineServiceDetails
              priorAuthReferralSourceFacilityList={
                priorAuthReferralSourceFacilityList
              }
              reAuthReferralSourceFacilityList={
                reAuthReferralSourceFacilityList
              }
              authNo={authNo}
              disciplineRequestorStatus={disciplineRequestorStatus}
              disciplineTemplateStatus={disciplineTemplateStatus}
              disciplineTypeStatus={disciplineTypeStatus}
              getDisciplineRequestorResp={getDisciplineRequestorResp}
              getDisciplineTemplateResp={getDisciplineTemplateResp}
              getDisciplineTypeResp={getDisciplineTypeResp}
              getReAuthTimelineData={getReAuthTimelineData}
              getReferralDisciplinesData={getReferralDisciplinesData}
              getReferralServicestatusResp={getReferralServicestatusResp}
              getServiceStatusReasonResp={getServiceStatusReasonResp}
              getUrgencyMasterResp={getUrgencyMasterResp}
              referralDisciplines={referralDisciplines}
              referralServiceStatus={referralServiceStatus}
              serviceDetails={serviceDetails}
              serviceStatusReasonStatus={serviceStatusReasonStatus}
              setServiceDetails={setServiceDetails}
              updateDisciplinesData={updateDisciplinesData}
              updatePhysicianReviewData={updatePhysicianReviewData}
              urgencyStatus={urgencyStatus}
              updatedDisciplineResp={updatedDisciplineResp}
              disciplineServiceDetailsError={disciplineServiceDetailsError}
              getUpdateSurveyDetails={getUpdateSurveyDetails}
              handleRef={handleSelfRef}
              resetStates={resetStates}
              patientDetailData={patientDetailData}
              getDisciplineNoteList={getDisciplineNoteList}
              disciplineNoteListData={disciplineNoteListData}
              getDisciplineLevelNotes={getDisciplineLevelNotes}
              saveDisciplineLevelNotesData={saveDisciplineLevelNotesData}
              updateDisciplineLevelNoteData={updateDisciplineLevelNoteData}
              GetDisciplineLevelNotesData={GetDisciplineLevelNotesData}
              saveDisciplineLevelNotesDataResp={
                saveDisciplineLevelNotesDataResp
              }
              updateDisciplineLevelNoteDataresp={
                updateDisciplineLevelNoteDataresp
              }
              authorizationDetailTabsData={authorizationDetailTabsData}
              userDetails={userDetails}
              getDisciplinesData={getDisciplinesData}
              getStaffList={getStaffList}
              userList={userList}
              updateAssignStaff={updateAssignStaff}
              disciplineRecord={disciplineRecord}
              receivedMethodData={receivedMethodData}
              getReceivedMethodDetails={getReceivedMethodDetails}
              putMyPatientListData={putMyPatientListData}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Disciplines;
