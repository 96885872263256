import { Box, Button, Divider, Grid, Modal, Typography } from "@mui/material";
import { FaFax, FaUserMd } from "react-icons/fa";
import { isEmpty, isNil } from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import {
  BreakWord,
  FaChevronCircleDownIcon,
  FaChevronCircleUpIcon,
  FieldLabel,
  FieldValue,
  FollowingUpPhysicianGrid,
  Gridbody,
  GridContent,
  Header,
  HeaderTitle,
  HorizontalDivider,
  Item,
  ItemIconBox,
  SummaryBox,
  VerticalDivider,
} from "../styles/AuthPhysicianDetails";
import { colors } from "../../../../../styles/colors";
import { displayFlex } from "../../../../../styles/mui/styles/display";
import { FaPhoneInputIcon } from "../../../../../components/formComponents/styles/style";
import fontWeight from "../../../../../styles/mui/fontWeight";
import PhysicianInputForm from "../../ReferralIntake/containers/PhysicianInputForm";
import {
  CancelButton,
  FooterBox,
  GridContainer,
  ModalHeaderBox,
  ModalSubtitle,
  PhysicianModalGrid,
  PhysicianModalTitle,
  SubtitleHorizontalDivider,
} from "../styles/ModifyRequestModal";
import {
  AuthPhysicianDetails,
  PhysicianDetails as PhysicianDetailsModel,
} from "../../../../../models/PhysicianDetails";
import { getFormattedPhoneNo, getValue } from "../../../../../utils";
import {
  AuthOrderingPhysicianDetailsActionDispatchType,
  physicianInitialData,
  PhysicianTypeID,
} from "../../../../../constants/PhysicianDetails";
import { useParams } from "react-router-dom";
import { UserDetailsPayload } from "../../../../../models/User";
import { AuthDetailsAccess } from "../../../../../constants/Permission";
import Permission from "../../../../Permission";
import {
  IconFaTimesCircle,
  ModalGridContainer,
} from "../../../../../styles/common/style";

export interface PropsFromDispatch {
  getFollowingPhysicianDetails: (referralId: string) => void;
  getOrderingPhysicianDetails: (referralId: string) => void;
  resetStates: (actionType: string[]) => void;
  putFollowingPhysicianDetails: (
    referralId: string,
    payload: AuthPhysicianDetails
  ) => void;
  putOrderingPhysicianDetails: (
    referralId: string,
    payload: AuthPhysicianDetails
  ) => void;
}

export interface PropsFromState {
  followingPhysicianDetails: AuthPhysicianDetails;
  orderingPhysicianDetails: AuthPhysicianDetails;
  user: UserDetailsPayload;
  modifyOrderingProvider: boolean;
  modifyFollowingProvider: boolean;
}
type AllProps = PropsFromState & PropsFromDispatch;

const PhysicianDetails: React.FC<AllProps> = ({
  followingPhysicianDetails,
  getFollowingPhysicianDetails,
  getOrderingPhysicianDetails,
  orderingPhysicianDetails,
  putFollowingPhysicianDetails,
  putOrderingPhysicianDetails,
  user,
  modifyOrderingProvider,
  modifyFollowingProvider,
  resetStates,
}: AllProps) => {
  const [orderingPhysicianData, setOrderingPhysicianData] = useState(
    orderingPhysicianDetails
  );
  const [followingPhysicianData, setFollowingPhysicianData] = useState(
    followingPhysicianDetails
  );
  const [openModal, setOpenModal] = useState(false);
  const [showProvider, setShowProvider] = useState(false);

  const [modal, setModal] = useState<string>("");
  const { authNo } = useParams();
  const referralId = authNo;

  useEffect(() => {
    if (orderingPhysicianDetails && referralId) {
      getOrderingPhysicianDetails(referralId);
    }
    return () => {
      resetStates([
        AuthOrderingPhysicianDetailsActionDispatchType.GET_ORDERING_PHYSICIAN_DETAILS_RESET,
      ]);
    };
  }, []);

  useEffect(() => {
    setOrderingPhysicianData(orderingPhysicianDetails);
  }, [orderingPhysicianDetails]);

  useEffect(() => {
    if (followingPhysicianDetails && referralId) {
      getFollowingPhysicianDetails(referralId);
    }
  }, []);

  useEffect(() => {
    setFollowingPhysicianData(followingPhysicianDetails);
  }, [followingPhysicianDetails]);

  const handleModifyRequest = (type: string) => {
    setOpenModal(true);
    setModal(type);
  };

  const modifyDetails = (buttonName: string) => {
    if (buttonName === "MODIFY ORDERING PHYSICIAN") {
      return !modifyOrderingProvider;
    }
    return !modifyFollowingProvider;
  };

  const header = (title: string, buttonName: string) => {
    return (
      <Box sx={Header}>
        <Box sx={displayFlex}>
          <FaUserMd size={"1.2rem"} color={colors.fonts[18]} />
          <Typography
            variant="subtitle1"
            color={colors.fonts[20]}
            fontWeight={fontWeight.Weight[5]}
            sx={HeaderTitle}
          >
            {title}
          </Typography>
        </Box>
        <Permission
          controlId={
            buttonName === "MODIFY ORDERING PHYSICIAN"
              ? `|${AuthDetailsAccess.ORDERING_PHYSICIAN_AUTH_DETAILS_READ}|${AuthDetailsAccess.ORDERING_PHYSICIAN_AUTH_DETAILS_UPDATE}`
              : `|${AuthDetailsAccess.FOLLOWING_PHYSICIAN_AUTH_DETAILS_READ}|${AuthDetailsAccess.FOLLOWING_PHYSICIAN_AUTH_DETAILS_UPDATE}`
          }
          passThrough
        >
          {(isDisabled: boolean) => (
            <Button
              variant="contained"
              disabled={modifyDetails(buttonName) || isDisabled}
              onClick={() =>
                handleModifyRequest(
                  buttonName === "MODIFY ORDERING PHYSICIAN"
                    ? "ordering"
                    : "following"
                )
              }
            >
              {buttonName}
            </Button>
          )}
        </Permission>
      </Box>
    );
  };

  return (
    <>
      <Grid container sx={Gridbody}>
        <Grid item xs={12}>
          <Box sx={displayFlex}>
            {showProvider ? (
              <FaChevronCircleDownIcon onClick={() => setShowProvider(false)} />
            ) : !showProvider ? (
              <FaChevronCircleUpIcon onClick={() => setShowProvider(true)} />
            ) : (
              ""
            )}
            {header("ORDERING PROVIDER:", "MODIFY ORDERING PHYSICIAN")}
          </Box>
        </Grid>
        <Divider light sx={HorizontalDivider} />
        {!showProvider && (
          <Grid container>
            <Grid item xs={12} sx={GridContent}>
              <Grid container>
                <Grid item xs={2} p={"0.8rem"}>
                  {fieldRender(
                    "Physician Name:",
                    getValue(orderingPhysicianData, "physicianName", "")
                  )}
                </Grid>
                <Divider orientation="vertical" flexItem sx={VerticalDivider} />
                <Grid item xs={2.5} sx={SummaryBox}>
                  {fieldRender(
                    "NPI:",
                    getValue(orderingPhysicianData, "physicianNpiId", "")
                  )}
                  {fieldRender(
                    "Tax ID:",
                    getValue(orderingPhysicianData, "taxId", "")
                  )}
                </Grid>
              </Grid>
              <Divider light sx={HorizontalDivider} />
            </Grid>
            <Grid item xs={10.5} sx={GridContent}>
              <Box sx={SummaryBox}>
                {orderingPhysicianData && (
                  <Grid container sx={BreakWord}>
                    <Grid item xs={1.8}>
                      {fieldRenderData(
                        "Street Name 1:",
                        getValue(orderingPhysicianData, "streetName1")
                      )}
                    </Grid>
                    <Grid item xs={1.8}>
                      {fieldRenderData(
                        "Street Name 2:",
                        getValue(orderingPhysicianData, "streetName2")
                      )}
                    </Grid>
                    <Grid item xs={1.5}>
                      {fieldRenderData(
                        "City:",
                        getValue(orderingPhysicianData, "city")
                      )}
                    </Grid>
                    <Grid item xs={1.5}>
                      {fieldRenderData(
                        "State:",
                        getValue(orderingPhysicianData, "physicianState")
                      )}
                    </Grid>
                    <Grid item xs={1}>
                      {fieldRenderData(
                        "Zipcode:",
                        getValue(orderingPhysicianData, "zipCode")
                      )}
                    </Grid>
                    <Grid item xs={2.7}>
                      {fieldRender(
                        "Phone :",
                        getFormattedPhoneNo(
                          getValue(orderingPhysicianData, "phoneNo", "")
                        ),
                        <FaPhoneInputIcon />,
                        getValue(orderingPhysicianData, "phoneExt", "")
                      )}
                    </Grid>
                    <Grid item xs={1.7}>
                      {fieldRender(
                        "Fax No:",
                        getFormattedPhoneNo(
                          getValue(orderingPhysicianData, "fax", "")
                        ),
                        <FaFax />
                      )}
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Grid>
            <Divider sx={HorizontalDivider} />
            <Grid item xs={12} sx={FollowingUpPhysicianGrid}>
              {header("FOLLOWING PROVIDER:", "MODIFY FOLLOWING PHYSICIAN")}
            </Grid>
            <Divider light sx={HorizontalDivider} />
            <Grid item xs={12} sx={GridContent}>
              <Grid container>
                <Grid item xs={2} sx={Item}>
                  {fieldRender(
                    "Physician Name:",
                    getValue(followingPhysicianData, "physicianName", "")
                  )}
                </Grid>
                <Divider orientation="vertical" flexItem sx={VerticalDivider} />
                <Grid item xs={2.5} sx={SummaryBox}>
                  {fieldRender(
                    "NPI",
                    getValue(followingPhysicianData, "physicianNpiId", "")
                  )}
                  {fieldRender(
                    "Tax ID:",
                    getValue(followingPhysicianData, "taxId", "")
                  )}
                </Grid>
              </Grid>
              <Divider light sx={HorizontalDivider} />
            </Grid>
            <Grid item xs={10.5} sx={GridContent}>
              <Box sx={SummaryBox}>
                <Grid container sx={BreakWord}>
                <Grid item xs={1.8}>
                  {fieldRenderData(
                    "Street Name 1:",
                    getValue(followingPhysicianData, "streetName1")
                  )}
                </Grid>
                <Grid item xs={1.8}>
                  {fieldRenderData(
                    "Street Name 2:",
                    getValue(followingPhysicianData, "streetName2")
                  )}
                </Grid>
                <Grid item xs={1.5}>
                  {fieldRenderData(
                    "City:",
                    getValue(followingPhysicianData, "city")
                  )}
                </Grid>
                <Grid item xs={1.5}>
                  {fieldRenderData(
                    "State:",
                    getValue(followingPhysicianData, "physicianState")
                  )}
                </Grid>
                <Grid item xs={1}>
                  {fieldRenderData(
                    "Zipcode:",
                    getValue(followingPhysicianData, "zipCode")
                  )}
                </Grid>
                <Grid item xs={2.7}>
                  {fieldRender(
                    "Phone :",
                    getFormattedPhoneNo(
                      getValue(followingPhysicianData, "phoneNo", "")
                    ),
                    <FaPhoneInputIcon />,
                    getValue(followingPhysicianData, "phoneExt", "")
                  )}
                </Grid>
                <Grid item xs={1.7}>
                  {fieldRender(
                    "Fax No:",
                    getFormattedPhoneNo(
                      getValue(followingPhysicianData, "fax", "")
                    ),
                    <FaFax />
                  )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
      <ModifyRequestModal
        openModal={openModal}
        closeModal={setOpenModal}
        physicianDetails={
          modal === "ordering" ? orderingPhysicianData : followingPhysicianData
        }
        putPhysicianDetails={
          modal === "ordering"
            ? putOrderingPhysicianDetails
            : putFollowingPhysicianDetails
        }
        type={modal}
        refId={referralId || ""}
        user={user}
      />
    </>
  );
};

const fieldRender = (
  label: string,
  value: string | number | null,
  icon?: any,
  extension?: string
) => {
  return (
    <Box>
      <Box>
        <Typography
          variant="body1"
          fontWeight={fontWeight.Weight[3]}
          color={colors.fonts[2]}
          sx={FieldLabel}
        >
          {label}
        </Typography>
      </Box>

      <Box sx={BreakWord}>
        {value && icon ? <Box sx={ItemIconBox}>{icon}</Box> : ""}
        <Typography
          variant={label === "Physician Name:" ? "h6" : "subtitle1"}
          fontWeight={fontWeight.Weight[5]}
          color={colors.fonts[1]}
          sx={FieldValue}
        >
          {value ? value : ""}
        </Typography>
        {extension && (
          <>
            <Typography
              variant={label === "Physician Name:" ? "h6" : "subtitle1"}
              fontWeight={fontWeight.Weight[5]}
              color={colors.fonts[1]}
              sx={FieldValue}
            >
              &nbsp; ext: &nbsp;
            </Typography>
            <Typography
              variant={label === "Physician Name:" ? "h6" : "subtitle1"}
              fontWeight={fontWeight.Weight[5]}
              color={colors.fonts[1]}
              sx={FieldValue}
            >
              {extension}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

const fieldRenderData = (label: string, value: string | number | null) => {
  return (
    <Box>
      <Typography
        variant="body1"
        fontWeight={fontWeight.Weight[3]}
        color={colors.fonts[2]}
        sx={FieldLabel}
      >
        {label}
      </Typography>
      <Typography
        variant="subtitle1"
        fontWeight={fontWeight.Weight[3]}
        color={colors.fonts[1]}
        sx={FieldValue}
      >
        {value ? value : ""}
      </Typography>
    </Box>
  );
};

type ModalProps = {
  openModal: boolean;
  closeModal: (onClose: boolean) => void;
  physicianDetails: AuthPhysicianDetails;
  putPhysicianDetails: (
    referralId: string,
    payload: AuthPhysicianDetails
  ) => void;
  type: string;
  refId: string;
  user: UserDetailsPayload;
};

const ModifyRequestModal = ({
  openModal,
  closeModal,
  physicianDetails,
  putPhysicianDetails,
  type,
  refId,
  user,
}: ModalProps) => {
  const initialState = {
    referralId: 0,
    referralPhysicianDetailId: 0,
    physicianId: 1,
    physicianName: "",
    physicianNpiId: "",
    physicianAddress: "",
    phoneNo: "",
    phoneExt: "",
    taxId: "",
    fax: "",
    streetName1: "",
    streetName2: "",
    city: "",
    county: "",
    physicianState: "",
    zipCode: "",
    userCreated: null,
    userUpdated: "",
  };

  const [physicianData, setPhysicianData] =
    useState<AuthPhysicianDetails>(initialState);

  const [addManually, setAddManually] = useState<boolean>(false);
  const [openProvider, setOpenProvider] = useState(false);
  const [inputValue, setInputValue] = useState<PhysicianDetailsModel | null>(
    null
  );

  useEffect(() => {
    setInputValue(physicianInitialData);
  }, [openModal]);

  const methods = useForm<AuthPhysicianDetails>({
    defaultValues: initialState,
  });

  const {
    getValues,
    setValue,
    control,
    reset,
    handleSubmit,
    watch,
    clearErrors,
  } = methods;

  const watchFields = watch(["county", "zipCode"]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "county" || name === "zipCode") {
        if (value) {
          clearErrors(name);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watchFields]);

  const handleClose = () => {
    closeModal(false);
    setPhysicianData(initialState);
    reset(initialState);
    setAddManually(false);
  };

  const choosePhysician = (option: any) => {
    if (!isNil(option)) {
      const {
        physicianName,
        physicianNpiId,
        taxId,
        city,
        physicianState,
        zipCode,
        streetName1,
        streetName2,
        physicianId,
        phoneNo,
        phoneExt,
        fax,
        physicianUid,
        county,
      } = option;
      if (type && type !== "ordering") {
        setValue("followingPhysicianId", physicianId);
        setValue("county", county);
        setValue(
          "serviceRequestId",
          physicianDetails && physicianDetails.serviceRequestId
            ? physicianDetails.serviceRequestId
            : 0
        );
      }
      setValue("physicianUid", physicianUid);
      reset({
        ...getValues(),
        physicianId: physicianId,
        physicianName: physicianName,
        physicianNpiId: physicianNpiId,
        taxId: taxId,
        phoneNo: phoneNo ? phoneNo : "",
        phoneExt: phoneExt ? phoneExt : "",
        fax: fax,
        streetName1: streetName1, // not in master data (TBD)
        streetName2: streetName2, // not in master data (TBD)
        city: city,
        physicianState: physicianState,
        zipCode: zipCode,
        county: county,
      });
    }
    setPhysicianData(option);
    setAddManually(false);
  };

  const onSubmit = (data: AuthPhysicianDetails) => {
    // create new object and assign data values to complete the object properties
    const userName = getValue(user, "email");
    const referralId = String(refId || physicianDetails.referralId);
    const details = {
      ...data,
      referralId: physicianDetails.referralId,
      referralPhysicianDetailId: physicianDetails.referralPhysicianDetailId,
      userCreated: userName,
      userUpdated: userName,
    };
    putPhysicianDetails(referralId, details);
    closeModal(false);
    setAddManually(false);
    setPhysicianData(initialState);
    reset(initialState);
  };

  const handleAddManually = () => {
    const initialState = {
      referralId: 0,
      referralPhysicianDetailId: 0,
      physicianId: 1,
      physicianName: "",
      physicianNpiId: "",
      physicianAddress: "",
      phoneNo: "",
      phoneExt: "",
      taxId: "",
      fax: "",
      streetName1: "",
      streetName2: "",
      city: "",
      physicianState: "",
      zipCode: "",
      userCreated: null,
      userUpdated: "",
      physicianTypeId: PhysicianTypeID.DEFAULT,
    };
    setAddManually(!addManually);
    setPhysicianData(initialState);
    reset({
      physicianId: 0,
      physicianName: "",
      physicianNpiId: "",
      phoneNo: "",
      phoneExt: "",
      taxId: "",
      fax: "",
      streetName1: "", // not in master data (TBD)
      streetName2: "", // not in master data (TBD)
      city: "",
      county: "",
      physicianState: "",
      zipCode: "",
    });
    if (type && type !== "ordering") {
      setValue("followingPhysicianId", 0);
      setValue(
        "serviceRequestId",
        physicianDetails && physicianDetails.serviceRequestId
          ? physicianDetails.serviceRequestId
          : 0
      );
    }
    setValue("physicianUid", uuidv4());
  };

  const physicianFormNames = {
    physicianName: "physicianName",
    physicianNpiId: "physicianNpiId",
    taxId: "taxId",
    phoneNo: "phoneNo",
    phoneExt: "phoneExt",
    fax: "fax",
    address: [
      "streetName1",
      "streetName2",
      "city",
      "county",
      "physicianState",
      "zipCode",
    ],
  };

  return (
    <>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={ModalGridContainer}
      >
        <Box sx={PhysicianModalGrid}>
          <Grid container>
            <Grid item xs={11.2} sx={ModalHeaderBox}>
              <Typography
                variant="h6"
                color={colors.fonts[20]}
                fontWeight={fontWeight.Weight[5]}
                sx={PhysicianModalTitle}
              >
                ADD {type} PROVIDER:
              </Typography>
            </Grid>
            <Grid item xs={0.5} pb={"1rem"}>
              <IconFaTimesCircle onClick={handleClose} />
            </Grid>
          </Grid>
          <Grid container sx={GridContainer}>
            <Grid item xs={12} pl={"0.5rem"}>
              <Typography
                variant="subtitle1"
                color={colors.black[2]}
                fontWeight={fontWeight.Weight[4]}
                sx={ModalSubtitle}
              >
                Search {type} providers and assign from here:
              </Typography>
              <Typography
                variant="body1"
                color={colors.black[5]}
                fontWeight={fontWeight.Weight[2]}
              >
                View {type} provider details in the result area and assign them
                by clicking on &quot;Add&quot; button.
              </Typography>
            </Grid>
            <Divider light sx={SubtitleHorizontalDivider} />
            <Grid item xs={12} sx={ModalHeaderBox}>
              <PhysicianInputForm
                // useForm hook methods
                control={control}
                getValues={getValues}
                setValue={setValue}
                reset={reset}
                // assign selected state and form names
                physician={physicianData}
                names={physicianFormNames}
                // assign handlers
                choosePhysician={choosePhysician}
                addManually={addManually}
                handleAddManually={handleAddManually}
                helper={undefined}
                modal={true}
                setOpen={setOpenProvider}
                open={openProvider}
                isSearchVisible={true}
                name={""}
                required={true}
                // assign value for autoComplete
                inputValue={inputValue || null}
                setInputValue={setInputValue}
              />
            </Grid>
          </Grid>
          <Box sx={FooterBox}>
            <Button variant="outlined" onClick={handleClose} sx={CancelButton}>
              Cancel
            </Button>
            <Button
              disabled={
                addManually ? false : isEmpty(getValues("physicianName"))
              }
              variant="contained"
              onClick={handleSubmit(onSubmit)}
            >
              +Add
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default PhysicianDetails;
