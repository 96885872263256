import { SyntheticEvent, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { FaMinus, FaPlus } from "react-icons/fa";
import { debounce, isArray, isEmpty, isUndefined, toUpper } from "lodash";
import { useForm, Controller, useWatch } from "react-hook-form";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import EventIcon from "@mui/icons-material/Event";

import { colors } from "../../../../../styles/colors";
import {
  isExpedited,
  getList,
  getValue,
  handleInputEvent,
  isNil,
  serverSideErrorMsg,
  length,
  isValuePresentInObjects,
  fromProviderPortal,
  getServiceRequestNumber,
} from "../../../../../utils";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  BackBtn,
  ButtonGrid,
  CancelBtn,
  DataGrid,
  DateFormControl,
  DateTimeGrid,
  DescriptionStyle,
  DisciplineGridbody,
  DisciplineTypeGrid,
  FaIconBox,
  FaMinusIconGrid,
  Gridbody,
  GridbodyStatus,
  Gridbodytemplate,
  HorizontalDivider,
  ReceivedMethodsStyle,
  RequestedVisitIcon,
  RequestedVisits,
  ServiceDetailsGridContainer,
  ServiceRequesterName,
  TemplateGrid,
  TranslationText,
  UrgencyChangeGrid,
  UrgencyDateTimeChangeGrid,
} from "../styles/Disciplines";
import DatePicker from "../../../../../components/formComponents/DatePicker";
import { FormInputDropdown } from "../../../../../components/formComponents/FormInputDropdown";
import {
  DisciplineData,
  ReferralDisciplineActionDispatchTypes,
  ReferralDisciplinesStages,
  UpdateDisciplineActionDispatchTypes,
} from "../../../../../constants/Discipline";
import {
  Discipline,
  DisciplineNoteSearch,
  ReferralDiscipline,
  UpdatedReferralDiscipline,
} from "../../../../../models/Disciplines";
import { AssignedStaff, ReceivedMethods } from "../../../../../models/Patient";
import {
  DisciplineTemplate,
  DisciplineType,
  RationaleTemplatePayload,
  ReferralServiceStatus,
} from "../../../../../models/Master";
import { ReceivedMethodMasterResponse, UrgencyStatusMasterResponse } from "../../../../../models/Api/Patient";
import {
  DisciplineRequestorResponse,
  DisciplineTemplateResponse,
  DisciplineTypeResponse,
  ReferralServiceStatusResponse,
  ServiceStatusReasonResponse,
} from "../../../../../models/Api/Master";
import { AllowedVisitCounts } from "../../../../../constants/Service";
import { displayFlex } from "../../../../../styles/mui/styles/display";
import { FormInputText } from "../../../../../components/formComponents/FormInputText";
import {
  PutAuthorizationDetailTabActionDispatchTypes,
  UrgencyReasonTextLength,
} from "../../../../../constants/Authorization";
import CustomButton from "../../../../../components/custom/button/components/Button";
import { UpdateDisciplineResponse } from "../../../../../models/Api/Discipline";
import { FormInputTimePicker } from "../../../../../components/formComponents/FormInputTimePicker";
import { rules } from "../../../../../utils/validation/Validation";
import NotesTable from "../containers/NotesTable";
import {
  DISCIPLINE_DETAILS,
  PatientPreferredLanguage,
} from "../../../../../constants/Constants";
import { PatientInformationViewResponse } from "../../../../../models/Api/PatientInformation";
import {
  DisciplineNoteResponse,
  GetDisciplineLevelNote,
  SaveDisciplineLevelNote,
  UpdateDisciplineLevelNote,
} from "../../../../../models/Api/Notes";
import {
  DisciplineLevelNote,
  DisciplineNote,
} from "../../../../../models/Notes";
import { DISCIPLINE_PER_PAGE } from "../../../../../constants/FunctionalUtils";
import {
  DisciplineNoteValues,
  GetAdminNotesByVersionIdActionDispatchTypes,
  GetDisciplineLevelNotesActionDispatchTypes,
  NoteType,
  NotesReviewData,
  SaveDisciplineLevelNotesActionDispatchTypes,
  SaveReferralLevelNotesActionDispatchTypes,
} from "../../../../../constants/Notes";
import {
  ServiceRequestType,
  ServiceStatusValue,
} from "../../../../../constants/Master";
import Permission from "../../../../Permission";
import { AuthDetailsAccess } from "../../../../../constants/Permission";
import { IconDisabled } from "../../../../../styles/common/style";
import { LoggedInUserResponse } from "../../../../../models/Api/User";
import { DropdownOption } from "../../../../../components/formComponents/constant/FormComponents";
import { AuthorizationDetailTabsResponse } from "../../../../../models/Api/Authorization";
import { ActionType } from "../../../../../constants/Staffing";
import AssignStaffModalUI from "../../../../../components/custom/assignStaffModalUI/components/AssignStaffModalUI";
import { UserListWithFilterPayload, User } from "../../../../../models/User";
import ReadOnlyField from "../../../../../components/formComponents/ReadOnlyField";
import { formatDate } from "../../../../../utils/DateTime";
import { DateFormats } from "../../../../../constants/Date";
import { MyPatientResponse } from "../../../../../models/Api/AllPatientRecord";
import { DocumentFlowType } from "../../../../../constants/AllPatientRecord";

export interface PropsFromState {
  authNo: string | undefined;
  disciplineRequestorStatus: DisciplineRequestorResponse;
  disciplineTemplateStatus: DisciplineTemplateResponse;
  disciplineTypeStatus: DisciplineTypeResponse;
  referralDisciplines: ReferralDiscipline;
  referralServiceStatus: ReferralServiceStatusResponse;
  serviceDetails: any;
  serviceStatusReasonStatus: ServiceStatusReasonResponse;
  setServiceDetails: any;
  urgencyStatus: UrgencyStatusMasterResponse;
  updatedDisciplineResp: UpdateDisciplineResponse;
  disciplineServiceDetailsError: any;
  priorAuthReferralSourceFacilityList: DropdownOption[];
  reAuthReferralSourceFacilityList: DropdownOption[];
  patientDetailData: PatientInformationViewResponse;
  disciplineNoteListData: DisciplineNoteResponse;
  GetDisciplineLevelNotesData: GetDisciplineLevelNote;
  saveDisciplineLevelNotesDataResp: SaveDisciplineLevelNote;
  updateDisciplineLevelNoteDataresp: UpdateDisciplineLevelNote;
  authorizationDetailTabsData: AuthorizationDetailTabsResponse;
  userDetails: LoggedInUserResponse;
  userList: User[];
  disciplineRecord: Discipline;
  receivedMethodData: ReceivedMethodMasterResponse;
  putMyPatientListData: MyPatientResponse
}

export interface PropsFromDispatch {
  getDisciplineRequestorResp: () => void;
  getDisciplineTypeResp: () => void;
  getReAuthTimelineData: (referralId: string) => void;
  getReferralDisciplinesData: (referralDisciplineId: number) => void;
  getReferralServicestatusResp: (value: number) => void;
  getServiceStatusReasonResp: (serviceId: number) => void;
  getUrgencyMasterResp: () => void;
  getUpdateSurveyDetails: (data: any) => void;
  updateDisciplinesData: (
    authDisciplineId: number,
    payload: UpdatedReferralDiscipline
  ) => void;
  updatePhysicianReviewData: (
    authDisciplineId: number,
    payload: ReferralDiscipline
  ) => void;
  getDisciplineTemplateResp: (payload: RationaleTemplatePayload) => void;
  handleRef: () => void;
  resetStates: (actionType: string[]) => void;
  getDisciplineNoteList: (payload: DisciplineNoteSearch) => void;
  getDisciplineLevelNotes: (disciplineNoteId: number) => void;
  updateDisciplineLevelNoteData: (
    disciplineNoteId: number,
    payload: DisciplineLevelNote
  ) => void;
  saveDisciplineLevelNotesData: (payload: DisciplineLevelNote) => void;
  getDisciplinesData: (referralId: number) => void;
  getStaffList: (payload: UserListWithFilterPayload) => void;
  updateAssignStaff: (
    assignedToStaffId: number,
    payload: AssignedStaff
  ) => void;
  getReceivedMethodDetails: (serviceRequestTypeId: number) => void;
}

type AllProps = PropsFromState & PropsFromDispatch;

const DisciplineServiceDetails: React.FC<AllProps> = ({
  disciplineTemplateStatus,
  disciplineTypeStatus,
  getDisciplineTemplateResp,
  getDisciplineTypeResp,
  getReferralDisciplinesData,
  getReferralServicestatusResp,
  getServiceStatusReasonResp,
  getUrgencyMasterResp,
  referralDisciplines,
  referralServiceStatus,
  serviceStatusReasonStatus,
  setServiceDetails,
  updateDisciplinesData,
  updatePhysicianReviewData,
  urgencyStatus,
  disciplineServiceDetailsError,
  handleRef,
  priorAuthReferralSourceFacilityList,
  reAuthReferralSourceFacilityList,
  resetStates,
  patientDetailData,
  disciplineNoteListData,
  getDisciplineNoteList,
  getDisciplineLevelNotes,
  saveDisciplineLevelNotesData,
  updateDisciplineLevelNoteData,
  GetDisciplineLevelNotesData,
  saveDisciplineLevelNotesDataResp,
  updateDisciplineLevelNoteDataresp,
  authorizationDetailTabsData,
  updatedDisciplineResp,
  userDetails,
  getDisciplinesData,
  userList,
  getStaffList,
  updateAssignStaff,
  disciplineRecord,
  getReceivedMethodDetails,
  receivedMethodData,
  putMyPatientListData
}: AllProps) => {
  const { loading: loadingUrgencyStatus, response: urgencyStatusResponse } =
    urgencyStatus;
  const { response: authorizationDetailTabsResponse } =
    authorizationDetailTabsData;
  const {
    loading: loadingReferralServiceStatus,
    response: referralServiceStatusResponse,
  } = referralServiceStatus;
  const { response: updatedAssignStaff } = putMyPatientListData;
  const {
    loading: loadingDisciplineTypeStatus,
    response: DisciplineTypeResponse,
  } = disciplineTypeStatus;
  const { response: ServiceStatusReasonResponse } = serviceStatusReasonStatus;

  const {
    loading: loadingDisciplineTemplate,
    response: DisciplineTemplateResponse,
  } = disciplineTemplateStatus;
  const {
    loading: loadingDisciplineNoteList,
    response: disciplineNoteListResponse,
  } = disciplineNoteListData;
  const { loading: loadingReceivedMethod, response: receviedMethodResponse } =
    receivedMethodData;
  const SearchData = {
    id: getValue(disciplineRecord, "serviceRequestDisciplineId"),
    pageNo: 1,
    pageSize: DISCIPLINE_PER_PAGE,
  };
  const { authNo } = useParams();
  const [disciplineTemplateState, setDisciplineTemplateState] = useState<
    DisciplineTemplate[] | any[]
  >([]);

  const [physicianReview, setPhysicianReview] = useState<boolean | null>(null);
  const [physicianReviewStatus, setPhysicianReviewStatus] =
    useState<boolean>(false);
  const [requestedErr, setRequestedErr] = useState<boolean>(false);
  const [preferredLanguage, setPreferredLanguage] = useState<string | null>();
  const [disciplineNoteList, setDisciplineNoteList] = useState<
    DisciplineNote[]
  >([]);
  const [openModal, setOpenModal] = useState<boolean>();
  const [disciplineDetails, setDisciplineDetails] =
  useState<ReferralDiscipline>(DisciplineData);
  const [disciplineNoteRowCount, setDisciplineNoteRowCount] =
    useState<number>(1);
  const [page, setPage] = useState(1);
  const [renderDisciplineNotes, setRenderDisciplineNotes] =
    useState<boolean>(false);
  const [pageName, setPageName] = useState<string>("");
  const [userName, setUserName] = useState<User | undefined | null>();
  const [availableStaffResponse, setAvailableStaffResponse] =
    useState(userList);
  const [errorFlag, setErrorFlag] = useState<boolean>(false);
  const [receivedMethod, setReceivedMethod] = useState<
    ReceivedMethods[] | any[]
  >([]);
  const [providerPortalOption, setProviderPortalOption] = useState<ReceivedMethods | null>(null)
  
  useEffect(() => {
    setPreferredLanguage(getValue(patientDetailData, "response.memberInfo.languageName"));
  }, [patientDetailData]);

  const [disablebuttons, setDisableButtons] = useState<boolean>(false);

  const methods = useForm<any>({
    defaultValues: DisciplineData,
    mode: "all",
  });

  const {
    control,
    reset,
    getValues,
    setValue,
    watch,
    handleSubmit,
    clearErrors,
    getFieldState,
    trigger,
    setError,
  } = methods;

  const values = getValues();

  const [serviceStatusId, setServiceStatusId] = useState<boolean>(false);

  const DisciplineServiceDetailsValues = useWatch({
    control,
  });

  useEffect(() => {
    receivedMethod &&
      length(receivedMethod) &&
      receivedMethod.forEach((singleOption) => {
        const result = singleOption.isIntegrationMethod && fromProviderPortal(
          singleOption,
          values,
          "receivedMethodId"
        );
        if (result) {
          setProviderPortalOption(singleOption)
        }
      })
  }, [receivedMethod])

  useEffect(() => {
    setValue(
      "serviceStatusReasonId",
      DisciplineServiceDetailsValues.serviceStatusReasonId
    );
  }, [DisciplineServiceDetailsValues.serviceStatusId]);

  useEffect(() => {
    if (getValue(disciplineRecord, "serviceRequestDisciplineId") !== 0) {
      getReferralDisciplinesData(
        getValue(disciplineRecord, "serviceRequestDisciplineId")
      );
    }
  }, [updatedAssignStaff]);

  const requestedVisitValidator = () => {
    const approvedVisits = getValues("approvedVisits");
    const deniedVisits = getValues("deniedVisits");
    const requestedVisits = getValues("requestedVisits");

    if (
      (approvedVisits > 0 || deniedVisits > 0) &&
      requestedVisits !== approvedVisits + deniedVisits &&
      isReAuthDiscipline()
    ) {
      return getValue(rules, "requestedVisit.required.message");
    }
    return true;
  };

  trigger("requestedVisits");

  const handleDecreaseCounter = (type: string) => {
    if (type === "requestedVisit") {
      if (
        DisciplineServiceDetailsValues.requestedVisits !== 0 &&
        DisciplineServiceDetailsValues.requestedVisits > 0
      ) {
        setValue(
          "requestedVisits",
            DisciplineServiceDetailsValues.requestedVisits - 1
        );
      }
    }
    if (type === "approvedVisit") {
      if (
        DisciplineServiceDetailsValues.approvedVisits !== 0 &&
        DisciplineServiceDetailsValues.approvedVisits > 0
      ) {
        setValue(
          "approvedVisits",
          DisciplineServiceDetailsValues.approvedVisits - 1
        );
      }
    }
    if (type === "deniedVisit") {
      if (
        DisciplineServiceDetailsValues.deniedVisits !== 0 &&
        DisciplineServiceDetailsValues.deniedVisits > 0
      ) {
        setValue(
          "deniedVisits",
          DisciplineServiceDetailsValues.deniedVisits - 1
        );
      }
    }
    if (type === "originalRequestVisit") {
      if (DisciplineServiceDetailsValues.originallyRequestedVisits !== 0) {
        setValue(
          "originallyRequestedVisits",
          DisciplineServiceDetailsValues.originallyRequestedVisits - 1
        );
      }
    }
  };

  useEffect(() => {
    if (!isNil(disciplineServiceDetailsError)) {
      serverSideErrorMsg(disciplineServiceDetailsError, setError);
    }
  }, [disciplineServiceDetailsError]);

  useEffect(() => {
    return () => {
      resetStates([
        ReferralDisciplineActionDispatchTypes.REFERRAL_DISCIPLINE_RESET,
        PutAuthorizationDetailTabActionDispatchTypes.PUT_AUTHORIZATION_DETAIL_TAB_RESET,
      ]);
    };
  }, []);

  useEffect(() => {
    if (!loadingDisciplineNoteList) {
      getDisciplineNoteList(SearchData);
    }
  }, []);

  useEffect(() => {
    if (!loadingReceivedMethod) {
      switch (getValues("serviceRequestTypeId")) {
        case ServiceRequestType.AUTH_ID:
          getReceivedMethodDetails(ServiceRequestType.AUTH_ID)
          break;
        case ServiceRequestType.RE_AUTH_ID:
          getReceivedMethodDetails(ServiceRequestType.RE_AUTH_ID)
          break;
        default:
          break;
      }
    }
  }, [getValues("serviceRequestTypeId")])

  useEffect(() => {
    setReceivedMethod(receviedMethodResponse || [])
  }, [receviedMethodResponse])

  useEffect(() => {
    if (
      !isNil(saveDisciplineLevelNotesDataResp) &&
      saveDisciplineLevelNotesDataResp.response.disciplineNoteId !== -1
    ) {
      getDisciplineNoteList(SearchData);
    }
    if (
      !isNil(updateDisciplineLevelNoteDataresp) &&
      updateDisciplineLevelNoteDataresp.response.disciplineNoteId !== -1
    ) {
      getDisciplineNoteList(SearchData);
    }
  }, [saveDisciplineLevelNotesDataResp, updateDisciplineLevelNoteDataresp]);

  useEffect(() => {
    if (disciplineNoteListResponse) {
      setDisciplineNoteList(
        disciplineNoteListResponse &&
        disciplineNoteListResponse.disciplineNoteDetail
      );
      setDisciplineNoteRowCount(
        disciplineNoteListResponse && disciplineNoteListResponse.totalRows
      );
    }
  }, [
    disciplineNoteListResponse &&
    disciplineNoteListResponse.disciplineNoteDetail,
  ]);

  const handlePageChange = (_e: any, page: number) => {
    setPage(page);
    const payload = {
      id: getValue(disciplineRecord, "serviceRequestDisciplineId"),
      pageNo: Number(page),
      pageSize: DISCIPLINE_PER_PAGE,
    };
    getDisciplineNoteList(payload);
  };

  const CharacterCount = (characterCount: any) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "0.3rem 0rem",
        }}
      >
        <Typography
          variant="body2"
          color={colors.black[1]}
          fontWeight={fontWeight.Weight[4]}
        >
          {UrgencyReasonTextLength - characterCount}
        </Typography>
        <Typography
          variant="body2"
          color={colors.fonts[4]}
          fontStyle="italic"
          fontWeight={fontWeight.Weight[3]}
          ml={"0.2rem"}
        >
          characters remaining..
        </Typography>
      </Box>
    );
  };

  const handleIncreaseCounter = (type: string) => {
    if (
      type === "requestedVisit" &&
      DisciplineServiceDetailsValues.requestedVisits < AllowedVisitCounts.MAX
    ) {
      setValue(
        "requestedVisits",
        DisciplineServiceDetailsValues.requestedVisits + 1
      );
    }
    if (
      type === "approvedVisit" &&
      DisciplineServiceDetailsValues.approvedVisits < AllowedVisitCounts.MAX
    ) {
      setValue(
        "approvedVisits",
        DisciplineServiceDetailsValues.approvedVisits + 1
      );
    }
    if (
      type === "deniedVisit" &&
      DisciplineServiceDetailsValues.deniedVisits < AllowedVisitCounts.MAX
    ) {
      setValue("deniedVisits", DisciplineServiceDetailsValues.deniedVisits + 1);
    }
    if (
      type === "originalRequestVisit" &&
      DisciplineServiceDetailsValues.originallyRequestedVisits <
      AllowedVisitCounts.MAX
    ) {
      setValue(
        "originallyRequestedVisits",
        DisciplineServiceDetailsValues.originallyRequestedVisits + 1
      );
    }
  };

  const handleVisits = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    visitType: string
  ) => {
    if (visitType === "requestedVisit") {
      setValue("requestedVisits", Number(event.target.value));
    }
    if (visitType === "approvedVisits") {
      setValue("approvedVisits", Number(event.target.value));
    }
    if (visitType === "deniedVisits") {
      setValue("deniedVisits", Number(event.target.value));
    }
    if (visitType === "originallyRequestedVisits") {
      setValue("originallyRequestedVisits", Number(event.target.value));
    }
  };

  useEffect(() => {
    if (
      !isValuePresentInObjects(
        filteredList(
          DisciplineServiceDetailsValues.approvedVisits,
          DisciplineServiceDetailsValues.deniedVisits,
          referralServiceStatusResponse
        ),
        getValues("serviceStatusId"),
        "value"
      )
    ) {
      setValue("serviceStatusId", null);
      setValue("serviceStatusReasonId", null);
    }
  }, [
    DisciplineServiceDetailsValues.approvedVisits,
    DisciplineServiceDetailsValues.deniedVisits,
  ]);

  useEffect(() => {
    if (getValue(disciplineRecord, "serviceRequestDisciplineId") !== 0) {
      getReferralDisciplinesData(
        getValue(disciplineRecord, "serviceRequestDisciplineId")
      );
    }
    if (!loadingUrgencyStatus) {
      getUrgencyMasterResp();
    }
    if (!loadingReferralServiceStatus) {
      getReferralServicestatusResp(
        DisciplineServiceDetailsValues.serviceStatusId > 0
          ? DisciplineServiceDetailsValues.serviceStatusId
          : getValue(disciplineRecord, "serviceStatusId")
      );
    }
    if (!loadingDisciplineTypeStatus) {
      getDisciplineTypeResp();
    }
  }, []);

  useEffect(() => {
    if (
      DisciplineServiceDetailsValues.serviceStatusId !== -1 &&
      DisciplineServiceDetailsValues.serviceStatusId > 0
    ) {
      getServiceStatusReasonResp(getValue(values, "serviceStatusId"));
    }
    if (DisciplineServiceDetailsValues.serviceStatusReasonId === 3) {
      setServiceStatusId(true);
    } else {
      setServiceStatusId(false);
    }
    if (DisciplineServiceDetailsValues.serviceStatusId != 1) {
      setServiceStatusId(false);
    }
  }, [
    DisciplineServiceDetailsValues.serviceStatusId,
    DisciplineServiceDetailsValues.serviceStatusReasonId,
  ]);

  useEffect(() => {
    if (
      getValue(updatedDisciplineResp, "response") &&
      getValue(updatedDisciplineResp, "loading") === false &&
      getValue(updatedDisciplineResp, "error") === null &&
      getValue(updatedDisciplineResp, "response.serviceRequestDisciplineId") !==
      -1
    ) {
      const response = {
        ...getValue(updatedDisciplineResp, "response"),
      };
      reset(response);
      getReferralDisciplinesData(
        getValue(disciplineRecord, "serviceRequestDisciplineId")
      );
      getReferralServicestatusResp(
        DisciplineServiceDetailsValues.serviceStatusId > 0
          ? DisciplineServiceDetailsValues.serviceStatusId
          : getValue(disciplineRecord, "serviceStatusId")
      );
    }
  }, [updatedDisciplineResp]);

  useEffect(() => {
    if (
      referralDisciplines &&
      disciplineDetails.serviceRequestDisciplineId ===
        getValue(DisciplineData, "serviceRequestDisciplineId")
    ) {
      setDisciplineDetails(referralDisciplines);
      const response = {
        ...referralDisciplines,
        facilityTypeId: getValue(referralDisciplines, "facilityTypeId") || null,
      };
      reset(response);
    } else {
      setValue(
        "assignedUserId",
        getValue(referralDisciplines, "assignedUserId")
      );
    }
    setPhysicianReview(values.agreedByPhysician);
  }, [referralDisciplines]);

  useEffect(() => {
    if (
      !loadingDisciplineTemplate &&
      values.serviceStatusId > 0 &&
      values.serviceStatusReasonId > 0
    ) {
      const searchData = {
        serviceRequestDisciplineId: Number(
          getValue(disciplineRecord, "serviceRequestDisciplineId")
        ),
        approvedVisits:
          DisciplineServiceDetailsValues.approvedVisits === null
            ? values.approvedVisits === null
              ? 0
              : values.approvedVisits
            : DisciplineServiceDetailsValues.approvedVisits,
        deniedVisits:
          DisciplineServiceDetailsValues.deniedVisits === null
            ? values.deniedVisits === null
              ? 0
              : values.deniedVisits
            : DisciplineServiceDetailsValues.deniedVisits,
        serviceStatusId:
          DisciplineServiceDetailsValues.serviceStatusId == null
            ? values.serviceStatusId
            : DisciplineServiceDetailsValues.serviceStatusId,
        serviceStatusReasonId:
          DisciplineServiceDetailsValues.serviceStatusReasonId == null
            ? values.serviceStatusReasonId
            : DisciplineServiceDetailsValues.serviceStatusReasonId,
      };

      debounce(() => {
        if (DisciplineServiceDetailsValues.deniedVisits >= 0) {
          getDisciplineTemplateResp(searchData);
        }
      }, 500)();
    }
  }, [
    DisciplineServiceDetailsValues.approvedVisits,
    DisciplineServiceDetailsValues.deniedVisits,
    DisciplineServiceDetailsValues.serviceStatusId,
    DisciplineServiceDetailsValues.serviceStatusReasonId,
  ]);

  const getServiceRequestTypeId = (
    disciplineTypeResponse: DisciplineType[],
    id: number
  ) => {
    if (isArray(disciplineTypeResponse) && id) {
      return disciplineTypeResponse.find(
        (item) => item.serviceRequestTypeId === id
      )?.serviceRequestTypeName;
    }
    return undefined;
  };

  const filteredList = (
    approvedVisit: number,
    deniedVisit: number,
    referralServiceStatusResponse: ReferralServiceStatus[]
  ) => {
    if (!referralServiceStatusResponse) return [];
    const referralServiceStatus =
      referralServiceStatusResponse &&
      referralServiceStatusResponse.map((item) => {
        return {
          label: item.serviceStatus,
          value: item.serviceStatusId,
        };
      });
    if (referralServiceStatusResponse) {
      if (approvedVisit === 0 && deniedVisit > 0) {
        return referralServiceStatus.filter(
          (item: DropdownOption) =>
            item.value != ServiceStatusValue.Partially_Approved &&
            item.value != ServiceStatusValue.Approved
        );
      }
      if (approvedVisit > 0 && deniedVisit === 0) {
        return referralServiceStatus.filter(
          (item: DropdownOption) =>
            item.value != ServiceStatusValue.Denied &&
            item.value != ServiceStatusValue.Partially_Approved
        );
      }
      if (approvedVisit > 0 && deniedVisit > 0) {
        return referralServiceStatus.filter(
          (item: DropdownOption) =>
            item.value != ServiceStatusValue.Approved &&
            item.value != ServiceStatusValue.Denied
        );
      }
    }
    return referralServiceStatus;
  };

  const filteredReceivedMethodList = (receivedMethodList: any) => {
    if (receivedMethodList) {
      return receivedMethodList.filter((item: any) => !item.isIntegrationMethod)
    }
  }

  const serviceRequestTypeName =
    getServiceRequestTypeId(
      DisciplineTypeResponse,
      getValues("serviceRequestTypeId")
    ) || "";

  useEffect(() => {
    const disciplineTemplateStatus =
      DisciplineTemplateResponse &&
      DisciplineTemplateResponse.map((item) => {
        return {
          label: item.templateName,
          value: item.rationaleTemplateId,
          rationale: item.rationale,
          description: item.resourceDescription,
        };
      });
    setDisciplineTemplateState(disciplineTemplateStatus || []);
  }, [DisciplineTemplateResponse]);

  const isSpanishLanguage = () => {
    let displaySpanish = true;
    if (preferredLanguage && !isEmpty(preferredLanguage.trim())) {
      const patientLanguageString = toUpper(preferredLanguage);
      if (patientLanguageString.includes(PatientPreferredLanguage.ENGLISH)) {
        displaySpanish = false;
      }
      if (patientLanguageString.includes(PatientPreferredLanguage.SPANISH)) {
        displaySpanish = true;
      }
    }
    return displaySpanish;
  };

  const getDisabledFlag = (serviceId: number | null) => {
    return (
      referralServiceStatusResponse.find(
        (id) => id.serviceStatusId === serviceId
      )?.isRationalDisable || false
    );
  };

  const getAgreedByPhysician = (agreedByPhysician: boolean | null) => {
    return agreedByPhysician !== null
      ? physicianReview === true
        ? "Agree"
        : "Disagree"
      : physicianReview === true
        ? "Agree"
        : physicianReview === null
          ? null
          : "Disagree"
  }

  const handleClick = () => {
    handleRef();
    resetStates([
      UpdateDisciplineActionDispatchTypes.UPDATE_DISCIPLINE_RESET,
      GetDisciplineLevelNotesActionDispatchTypes.GET_DISCIPLINE_LEVEL_NOTES_RESET,
      GetAdminNotesByVersionIdActionDispatchTypes.GET_NOTES_RESET,
      SaveReferralLevelNotesActionDispatchTypes.SAVE_REFERRAL_NOTES_RESET,
      SaveDisciplineLevelNotesActionDispatchTypes.SAVE_DISCIPLINE_NOTES_RESET,
    ]);
  };

  const handleBack = () => {
    getDisciplinesData(Number(authNo));
    setServiceDetails(false);
  };

  const onSubmit = (data: ReferralDiscipline) => {
    data.approvedVisits =
      getValues("approvedVisits") === null ? 0 : getValues("approvedVisits");
    data.deniedVisits =
      getValues("deniedVisits") === null ? 0 : getValues("deniedVisits");
    if (
      physicianReview !== null &&
      values.stageName === ReferralDisciplinesStages.PhysicianReview
    ) {
      data.agreedByPhysician = physicianReview;
      if (requestedErr === false) {
        const updateDetails = {
          ...data,
          requestingUserId: 0,
        };
        updatePhysicianReviewData(
          getValue(disciplineRecord, "serviceRequestDisciplineId"),
          updateDetails
        );
        setPhysicianReviewStatus(false);
        setRequestedErr(false);
      }
    } else if (
      referralDisciplines.stageName ===
      ReferralDisciplinesStages.ClinicalReview ||
      referralDisciplines.stageName === "Other"
    ) {
      data.agreedByPhysician = null;
      const requestingUserId = getValue(userDetails, "response.userId");
      if (requestedErr === false && requestingUserId) {
        const updateDetails: UpdatedReferralDiscipline = {
          ...data,
          requestingUserId,
        };
        updateDisciplinesData(
          getValue(disciplineRecord, "serviceRequestDisciplineId"),
          updateDetails
        );
        setRequestedErr(false);
      }
    } else {
      setPhysicianReviewStatus(true);
    }
    handleClick();
  };
  const handleServiceStatus = () => {
    setValue("serviceStatusReasonId", null);
  };
  const handleDisciplineTemplateChange = (event: any) => {
    setValue("otherResourceDescription", "");
    setValue("otherRationale", "");
    const templateId = event.target.value;
    setValue("rationaleTemplateId", event.target.value);
    const rationaleDes = disciplineTemplateState.filter(
      (id) => id.value === templateId
    );
    setValue("rationale", rationaleDes[0].rationale);
    setValue("resourceDescription", rationaleDes[0].description);
  };

  const handleDescription = (event: any, label: any) => {
    setValue(label, event.target.value);
  };

  const isVisitsPartiallyOrFullyDenied = () => {
    if (DisciplineServiceDetailsValues.deniedVisits) {
      return DisciplineServiceDetailsValues.deniedVisits > 0;
    }
    return false;
  };

  const renderTemplates = () => {
    return (
      isVisitsPartiallyOrFullyDenied() &&
      referralDisciplines.stageName !==
      ReferralDisciplinesStages.PhysicianReview
    );
  };

  useEffect(() => {
    if (getFieldState("serviceStatusReasonId").isDirty) {
      setValue("rationaleTemplateId", null);
    }

    if (
      referralDisciplines.agreedByPhysician === null ||
      referralDisciplines.stageName !==
      ReferralDisciplinesStages.PhysicianReview
    ) {
      setPhysicianReviewStatus(false);
    }
  }, [DisciplineServiceDetailsValues.serviceStatusReasonId, renderTemplates()]);

  const handlePhysicianReview = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if ((event.target as HTMLInputElement).value === "Agree") {
      setPhysicianReview(true);
    } else {
      setPhysicianReview(false);
    }
    setPhysicianReviewStatus(false);
  };

  const onSelection = (
    _e: SyntheticEvent<Element, Event>,
    value: User | null
  ) => {
    if (!isEmpty(value) && !isUndefined(value)) {
      setErrorFlag(false);
    }
    setUserName(value);
    setAvailableStaffResponse(
      value != null && !isNil(userList)
        ? userList.filter(
          (v: User) =>
            v.fullName === value.fullName && v.userId === value.userId
        )
        : userList
    );
  };
  const handleAssignStaff = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    setUserName(null);
    setErrorFlag(false)
  };

  const handleAssign = () => {
    if (
      !isNil(availableStaffResponse) &&
      !isEmpty(userName) &&
      !isUndefined(userName)
    ) {
      const data: AssignedStaff = {
        assignedUserId: getValue(userName, "userId"),
        assignedUserFullName: getValue(userName, "fullName"),
        userCreated: getValue(userName, "email"),
        disciplineRequestIds: [
          getValue(disciplineRecord, "serviceRequestDisciplineId"),
        ],
      };
      setOpenModal(false);
      updateAssignStaff(getValue(userName, "userId"), data);
      setErrorFlag(false);
      setUserName(null);
    } else {
      setErrorFlag(true);
    }
  };

  useEffect(() => {
    setAvailableStaffResponse(userList);
    const assignedUser = userList.filter((item: User) => {
      return item.userId === getValues("assignedUserId");
    });
    if (openModal && length(userList)) {
      setUserName(getValue(assignedUser, "[0]"));
    }
  }, [userList]);

  const getReferralSourceList = (serviceRequestTypeId: number) => {
    switch (serviceRequestTypeId) {
      case ServiceRequestType.AUTH_ID:
        return priorAuthReferralSourceFacilityList;
      case ServiceRequestType.RE_AUTH_ID:
        return reAuthReferralSourceFacilityList;
      default:
        return [];
    }
  };
  const requestorNameVisibility = (serviceStatusReasonId: number) => {
    if (ServiceStatusReasonResponse && length(ServiceStatusReasonResponse)) {
      const ServiceStatusReasonObject = ServiceStatusReasonResponse.find(
        (item) => item.serviceStatusReasonId === serviceStatusReasonId
      );
      if (ServiceStatusReasonObject) {
        return ServiceStatusReasonObject.captureRequesterName;
      }
    }
  };

  const isReAuthDiscipline = () => {
    return getValue(disciplineRecord, "serviceRequestTypeId", 0) === DocumentFlowType.ReAuth;
  }
  return (
    <>
      <Grid container sx={ServiceDetailsGridContainer}>
        <Grid item xs={4} pl={"1.5rem"}>
          <Box sx={[displayFlex]}>
            <Button variant="contained" sx={BackBtn} onClick={handleBack}>
              BACK
            </Button>
            <Box pl={"1.5rem"}>
              <Typography
                variant="h6"
                color={colors.fonts[20]}
                fontWeight={fontWeight.Weight[5]}
              >
                SERVICE DETAILS:
              </Typography>
              <Box sx={[displayFlex]} pt={"0.5rem"}>
                <Typography
                  variant="subtitle2"
                  fontWeight={fontWeight.Weight[5]}
                  color={colors.fonts[4]}
                >
                  CURRENT SERVICE:
                </Typography>
                <Box sx={[displayFlex]}>
                  <Typography
                    variant="subtitle1"
                    color={colors.fonts[4]}
                    fontWeight={fontWeight.Weight[5]}
                    display="inline"
                    pl={"0.2rem"}
                  >
                    &nbsp;Auth ID:{" "}
                    {getValue(
                      authorizationDetailTabsResponse,
                      "authorizationGeneralInfoDto.authorizationNumber"
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3} pl={"1.5rem"} pt={"0.9rem"}>
          <Controller
            defaultValue={""}
            control={control}
            name="requestingUserId"
            render={({ fieldState: { error } }) => (
              <FormHelperText>
                {error ? `* ${error.message}` : null}
              </FormHelperText>
            )}
          />
        </Grid>
        <Grid item xs={5} sx={ButtonGrid}>
          <Box sx={displayFlex}>
            <Button variant="contained" onClick={() => handleAssignStaff()}>
              ASSIGN STAFF
            </Button>
            <Button
              sx={CancelBtn}
              onClick={() => {
                handleBack();
                setDisableButtons(false);
                handleClick();
              }}
            >
              CANCEL
            </Button>
            <Permission
              controlId={`||${AuthDetailsAccess.DISCIPLINE_SAVE_SERVICE_DETAILS_UPDATE}`}
            >
              <Button
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                disabled={disablebuttons}
              >
                SAVE SERVICE DETAILS
              </Button>
            </Permission>
          </Box>
        </Grid>
        <Grid>
          {openModal && (
            <AssignStaffModalUI
              openModal={openModal}
              action={ActionType.ASSIGN_STAFF}
              displayDisciplineList={DISCIPLINE_DETAILS}
              handleClose={handleClose}
              handleAssign={handleAssign}
              userList={userList}
              getStaffList={getStaffList}
              userName={userName}
              availableStaffResponse={availableStaffResponse}
              onSelection={onSelection}
              errorFlag={errorFlag}
            />
          )}
        </Grid>
      </Grid>
      <Grid container sx={DisciplineGridbody}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={7}>
              <Grid container height={"100%"}>
                <Permission
                  controlId={`|${AuthDetailsAccess.DISCIPLINE_SERVICE_DETAILS_READ}|${AuthDetailsAccess.DISCIPLINE_SERVICE_DETAILS_UPDATE}`}
                  passThrough
                >
                  {(readOnly: boolean) => (
                    <Grid item xs={12} sx={Gridbody}>
                      <Grid container>
                        <Grid item xs={4} sx={DateTimeGrid}>
                          <FormControl variant="standard" sx={DateFormControl}>
                            <DatePicker
                              name="requestDateTime"
                              control={control}
                              label={"Date Request Received:"}
                              helper={rules.dateTimeRequired}
                              allowFutureDate={false}
                              readOnly={readOnly}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4.5} sx={DateTimeGrid}>
                          <FormControl variant="standard">
                            <FormInputTimePicker
                              name="requestDateTime"
                              control={control}
                              setValue={setValue}
                              label="Time Request Received:"
                              helper={rules.timeRequired}
                              allowFutureTime={false}
                              value={getValues("requestDateTime")}
                              readOnly={readOnly}
                              watch={watch}
                              trigger={trigger}
                              clearErrors={clearErrors}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={3.5} sx={DisciplineTypeGrid}>
                          <ReadOnlyField
                            label={"Type:"}
                            value={serviceRequestTypeName}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Permission>
                <Permission
                  controlId={`|${AuthDetailsAccess.DISCIPLINE_SERVICE_DETAILS_URGENCY_READ}|${AuthDetailsAccess.DISCIPLINE_SERVICE_DETAILS_URGENCY_UPDATE}`}
                  passThrough
                >
                  {(readOnly: boolean) => (
                    <Grid item xs={12} sx={Gridbody}>
                      <Grid item xs={12}>
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle1"
                            color={colors.black[2]}
                            fontWeight={fontWeight.Weight[5]}
                          >
                            URGENCY:
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider light sx={HorizontalDivider} />
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container sx={DataGrid}>
                            <Grid item xs={4} pl={"1rem"}>
                              <FormControl variant="standard" fullWidth>
                                <FormInputDropdown
                                  name="urgencyId"
                                  control={control}
                                  label="Urgency:"
                                  helper={rules.urgencyStatus}
                                  list={getList(urgencyStatusResponse, {
                                    label: "urgencyStatus",
                                    value: "urgencyId",
                                  })}
                                  readOnly={readOnly}
                                  InputStyle={isExpedited(
                                    getValues("urgencyId")
                                  )}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={5} sx={UrgencyChangeGrid}>
                              <Box sx={displayFlex}>
                                <Typography
                                  variant="body1"
                                  fontWeight={fontWeight.Weight[2]}
                                  color={colors.fonts[2]}
                                >
                                  Urgency Change:
                                </Typography>
                                <Box pl={"0.8rem"}>
                                  <Controller
                                    render={({ field }) => (
                                      <Switch
                                        {...field}
                                        checked={
                                          getValues("urgencyChanged") || false
                                        }
                                        disabled={readOnly}
                                      />
                                    )}
                                    name={`urgencyChanged`}
                                    control={control}
                                  />
                                </Box>
                                <Box pl={"0.8rem"}>
                                  <Typography
                                    variant="subtitle1"
                                    color={colors.black[2]}
                                    fontWeight={fontWeight.Weight[4]}
                                  >
                                    {getValues("urgencyChanged") ? "Yes" : "No"}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sx={UrgencyDateTimeChangeGrid}>
                          <Grid container>
                            <Grid item xs={4}>
                              <FormControl
                                variant="standard"
                                sx={DateFormControl}
                              >
                                <DatePicker
                                  name="urgencyChangeDateTime"
                                  control={control}
                                  label={"Change Date:"}
                                  allowFutureDate={false}
                                  readOnly={readOnly}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6} pl={"3.2rem"}>
                              <FormControl
                                variant="standard"
                                sx={DateFormControl}
                              >
                                <FormInputTimePicker
                                  name="urgencyChangeDateTime"
                                  control={control}
                                  setValue={setValue}
                                  label="Change Time:"
                                  value={getValues("urgencyChangeDateTime")}
                                  allowFutureTime={false}
                                  readOnly={readOnly}
                                  watch={watch}
                                  trigger={trigger}
                                  clearErrors={clearErrors}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Permission>
                <Permission
                  controlId={`|${AuthDetailsAccess.DISCIPLINE_SERVICE_DETAILS_VISIT_READ}|${AuthDetailsAccess.DISCIPLINE_SERVICE_DETAILS_VISIT_UPDATE}`}
                  passThrough
                >
                  {(readOnly: boolean) => (
                    <Grid item xs={12} sx={Gridbody}>
                      <Grid container direction="row" pt={"0.4rem"}>
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle1"
                            color={colors.black[2]}
                            fontWeight={fontWeight.Weight[5]}
                          >
                            VISITS:
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider light sx={HorizontalDivider} />
                        </Grid>
                        <Grid container sx={DataGrid}>
                          <Grid item xs={3.5}>
                            <VisitCounter
                              label="Requested Visits:"
                              name="requestedVisits"
                              isRequired={isReAuthDiscipline() && true}
                              handleDecreaseCounter={() =>
                                handleDecreaseCounter("requestedVisit")
                              }
                              handleIncreaseCounter={() =>
                                handleIncreaseCounter("requestedVisit")
                              }
                              handleChange={(
                                event: React.ChangeEvent<
                                  HTMLInputElement | HTMLTextAreaElement
                                >
                              ) => {
                                handleVisits(event, "requestedVisit");
                              }}
                              inputType="onlyNumber"
                              control={control}
                              requestedVisitValidator={requestedVisitValidator}
                              helper={isReAuthDiscipline() && rules.requestedVisit}
                              readOnly={readOnly}
                            />
                          </Grid>
                          <Grid item xs={3.5}>
                            <VisitCounter
                              label="Approved Visits:"
                              name="approvedVisits"
                              handleDecreaseCounter={() =>
                                handleDecreaseCounter("approvedVisit")
                              }
                              handleIncreaseCounter={() =>
                                handleIncreaseCounter("approvedVisit")
                              }
                              handleChange={(
                                event: React.ChangeEvent<
                                  HTMLInputElement | HTMLTextAreaElement
                                >
                              ) => {
                                handleVisits(event, "approvedVisits");
                              }}
                              inputType="onlyNumber"
                              control={control}
                              readOnly={readOnly}
                            />
                          </Grid>
                          <Grid item xs={3.5}>
                            <VisitCounter
                              label="Denied Visits:"
                              name="deniedVisits"
                              handleDecreaseCounter={() =>
                                handleDecreaseCounter("deniedVisit")
                              }
                              handleIncreaseCounter={() =>
                                handleIncreaseCounter("deniedVisit")
                              }
                              handleChange={(
                                event: React.ChangeEvent<
                                  HTMLInputElement | HTMLTextAreaElement
                                >
                              ) => {
                                handleVisits(event, "deniedVisits");
                              }}
                              inputType="onlyNumber"
                              control={control}
                              readOnly={readOnly}
                            />
                          </Grid>
                        </Grid>
                        <Divider light sx={HorizontalDivider} />
                        <Grid container sx={DataGrid}>
                          <Grid item xs={6} pl={"1rem"}>
                            <Typography
                              variant="body1"
                              fontWeight={fontWeight.Weight[2]}
                              color={colors.fonts[2]}
                            >
                              Discussion Status of Visits Accepted:
                            </Typography>
                            <Grid container>
                              <Grid item xs={2} sx={DataGrid}>
                                <Controller
                                  render={({ field }) => (
                                    <Switch
                                      {...field}
                                      checked={
                                        getValues("acceptedVisits") || false
                                      }
                                      disabled={readOnly}
                                    />
                                  )}
                                  name={`acceptedVisits`}
                                  control={control}
                                />
                              </Grid>
                              <Grid item xs={2} pt={"1rem"} pl={"0.5rem"}>
                                <Typography
                                  variant="subtitle1"
                                  color={colors.black[2]}
                                  fontWeight={fontWeight.Weight[4]}
                                >
                                  {getValues("acceptedVisits") ? "Yes" : "No"}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={4}>
                            <VisitCounter
                              label="Originally Requested Visits:"
                              name="originallyRequestedVisits"
                              handleDecreaseCounter={() =>
                                handleDecreaseCounter("originalRequestVisit")
                              }
                              handleIncreaseCounter={() =>
                                handleIncreaseCounter("originalRequestVisit")
                              }
                              handleChange={(
                                event: React.ChangeEvent<
                                  HTMLInputElement | HTMLTextAreaElement
                                >
                              ) => {
                                handleVisits(event, "originallyRequestedVisits");
                              }}
                              inputType="onlyNumber"
                              control={control}
                              requestedVisitValidator={requestedVisitValidator}
                              helper={rules.maxLengthDescription}
                              readOnly={readOnly}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Permission>
                <Permission
                  controlId={`|${AuthDetailsAccess.DISCIPLINE_SERVICE_DETAILS_STATUS_READ}|${AuthDetailsAccess.DISCIPLINE_SERVICE_DETAILS_STATUS_UPDATE}`}
                  passThrough
                >
                  {(readOnly: boolean) => (
                    <Grid
                      item
                      xs={12}
                      sx={GridbodyStatus(
                        DisciplineServiceDetailsValues.deniedVisits
                      )}
                    >
                      <Grid container direction="row" pb={"0.25rem"}>
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle1"
                            color={colors.black[2]}
                            fontWeight={fontWeight.Weight[5]}
                          >
                            STATUS
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider light sx={HorizontalDivider} />
                        </Grid>
                        <Grid item xs={12} pt={"0.5rem"}>
                          <Grid container>
                            <Grid item xs={4}>
                              {values.stageName ===
                                ReferralDisciplinesStages.PhysicianReview ? (
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="body1"
                                      color={colors.fonts[2]}
                                      fontWeight={fontWeight.Weight[3]}
                                    >
                                      Service Status:
                                    </Typography>
                                  </Grid>
                                  <Grid>
                                    <Typography
                                      variant="subtitle1"
                                      color={colors.black[2]}
                                      fontWeight={fontWeight.Weight[4]}
                                      pt={"1rem"}
                                    >
                                      Pending
                                    </Typography>
                                  </Grid>
                                </Grid>
                              ) : (
                                <FormControl variant="standard" fullWidth>
                                  <FormInputDropdown
                                    name="serviceStatusId"
                                    control={control}
                                    label="Service Status:"
                                    helper={rules.serviceStatus}
                                    list={filteredList(
                                      DisciplineServiceDetailsValues.approvedVisits,
                                      DisciplineServiceDetailsValues.deniedVisits,
                                      referralServiceStatusResponse
                                    )}
                                    onChangeHandler={handleServiceStatus}
                                    readOnly={readOnly}
                                  />
                                </FormControl>
                              )}
                            </Grid>
                            <Grid item xs={5} pl={"2rem"}>
                              <FormControl variant="standard" fullWidth>
                                <FormInputDropdown
                                  name="serviceStatusReasonId"
                                  control={control}
                                  label="Service Status Reason:"
                                  helper={rules.serviceStatusReason}
                                  list={getList(ServiceStatusReasonResponse, {
                                    label: "serviceStatusReason",
                                    value: "serviceStatusReasonId",
                                  })}
                                  disabled={
                                    DisciplineServiceDetailsValues.serviceStatusId <=
                                    0
                                  }
                                  readOnly={readOnly}
                                />
                              </FormControl>
                              {requestorNameVisibility(
                                getValues("serviceStatusReasonId")
                              ) && (
                                  <FormControl
                                    variant="standard"
                                    fullWidth
                                    sx={ServiceRequesterName}
                                  >
                                    <FormInputText
                                      name="statusReasonRequester"
                                      control={control}
                                      label="Withdrawal Requestor:"
                                      readOnly={readOnly}
                                      helper={rules.serviceRequesterName}
                                    />
                                  </FormControl>
                                )}
                            </Grid>
                          </Grid>
                        </Grid>
                        {renderTemplates() && (
                          <Grid item xs={12} pt={"1.5rem"}>
                            <Grid item xs={4}>
                              <FormControl variant="standard" fullWidth>
                                <FormInputDropdown
                                  name="rationaleTemplateId"
                                  control={control}
                                  label="Template:"
                                  list={disciplineTemplateState}
                                  onChangeHandler={(e: any) =>
                                    handleDisciplineTemplateChange(e)
                                  }
                                  readOnly={readOnly}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Permission>
              </Grid>
            </Grid>
            <Grid item xs={0.2}></Grid>
            <Grid item xs={4.8} sx={Gridbody}>
              <Permission
                controlId={`|${AuthDetailsAccess.DISCIPLINE_SERVICE_DETAILS_HCPCS_READ}|${AuthDetailsAccess.DISCIPLINE_SERVICE_DETAILS_HCPCS_UPDATE}`}
                passThrough
              >
                {(readOnly: boolean) => (
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid pb={"2rem"} pl={"0.7rem"}>
                        <ReadOnlyField
                          label={"HCPCS:"}
                          value={getValues("hcpcs")}
                        />
                      </Grid>
                      <Grid pl={"0.7rem"}>
                        <ReadOnlyField
                          label={"HCPCS Description:"}
                          value={values.hcpcsDescription}
                        />
                      </Grid>
                      <Divider light sx={HorizontalDivider} />
                      <Box sx={displayFlex}>
                        <Grid pl={"0.7rem"} xs={6}>
                          <ReadOnlyField
                            label={"Service Number:"}
                            value={
                              values.serviceNumber != -1
                                ? values.serviceNumber
                                : ""
                            }
                          />
                        </Grid>
                        <Grid pl={"0.7rem"} xs={6}>
                          <ReadOnlyField
                            label={"Service Request No:"}
                            value={getServiceRequestNumber(getValue(
                              disciplineRecord,
                              "serviceRequestNumber"
                            ))}
                          />
                        </Grid>
                      </Box>
                      <Grid container pl={"0.7rem"} pt={"1rem"} pb={"1rem"}>
                        <Grid item xs={6}>
                          <FormControl variant="standard" sx={DateFormControl}>
                            <DatePicker
                              name="effectiveFromDate"
                              control={control}
                              label={"Effective From:"}
                              allowFutureDate={true}
                              readOnly={readOnly}
                              maximumdate={dayjs(
                                getValues("effectiveThroughDate")
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl variant="standard" sx={DateFormControl}>
                            <DatePicker
                              name="effectiveThroughDate"
                              control={control}
                              label={"Effective Through:"}
                              allowFutureDate={true}
                              readOnly={readOnly}
                              minimumDate={dayjs(
                                getValues("effectiveFromDate")
                              )}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Divider light sx={HorizontalDivider} />
                      <Grid container pt={"1.5rem"} pb={"1rem"}>
                        <Grid item xs={6} sx={ReceivedMethodsStyle}>
                          <FormControl variant="standard" fullWidth>
                            <FormInputDropdown
                              name="facilityTypeId"
                              control={control}
                              label="Requested By:"
                              helper={rules.requestedBy}
                              list={getReferralSourceList(
                                getValues("serviceRequestTypeId")
                              )}
                              readOnly={readOnly}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} sx={ReceivedMethodsStyle} >
                          {
                            !isNil(providerPortalOption) ? (
                              <ReadOnlyField
                                label={"Received Methods:"}
                                value={getValue(providerPortalOption, "receivedMethodName")}
                              />
                            ) : (<FormControl variant="standard" fullWidth>
                              <FormInputDropdown
                                name="receivedMethodId"
                                control={control}
                                label="Received Methods:"
                                list={getList(filteredReceivedMethodList(receivedMethod), {
                                  label: "receivedMethodName",
                                  value: "receivedMethodId",
                                })}
                                readOnly={readOnly}
                              />
                            </FormControl>)
                          }
                        </Grid>
                      </Grid>
                      <Divider light sx={HorizontalDivider} />
                      <Grid container pl={"1rem"} pt={"1rem"}>
                        <Grid item xs={6}>
                          <FormControl variant="standard" sx={DateFormControl}>
                            <ReadOnlyField
                              label={"Created On:"}
                              value={
                                getValues("requestCreatedOn")
                                  ? formatDate(
                                    getValues("requestCreatedOn"),
                                    DateFormats.MM_DD_YYYY
                                  )
                                  : ""
                              }
                              icon={
                                <EventIcon fontSize="small" color="primary" />
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <Box pb={"1rem"} pl={"0.7rem"}>
                            <ReadOnlyField
                              label={"Created By:"}
                              value={values.createdUserFullName}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      <Divider light sx={HorizontalDivider} />
                      <Grid container pl={"0.5rem"} pt={"1rem"}>
                        <Grid item xs={6}>
                          <Box pb={"1rem"} pl={"0.7rem"}>
                            <ReadOnlyField
                              label={"Decided By:"}
                              value={values.decidedBy}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box pb={"1rem"} pl={"0.7rem"}>
                            <ReadOnlyField
                              label={"Decided Date/Time:"}
                              value={
                                values.decidedDateTime
                                  ? formatDate(
                                    values.decidedDateTime,
                                    DateFormats.MM_DD_YYYY_h_mm_a
                                  )
                                  : ""
                              }
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      <Divider light sx={HorizontalDivider} />
                      <Grid container pl={"0.5rem"} pt={"1rem"}>
                        <Grid item xs={6}>
                          <Box pb={"1rem"} pl={"0.7rem"}>
                            <ReadOnlyField
                              label={"Adjudicated By:"}
                              value={values.adjudicatedBy}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box pb={"1rem"} pl={"0.7rem"}>
                            <ReadOnlyField
                              label={"Adjudicated Date/Time:"}
                              value={
                                values.adjudicatedDateTime
                                  ? formatDate(
                                    values.adjudicatedDateTime,
                                    DateFormats.MM_DD_YYYY_h_mm_a
                                  )
                                  : ""
                              }
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      <Divider light sx={HorizontalDivider} />
                      <Box pb={"1rem"} pl={"1.2rem"}>
                        <ReadOnlyField
                          label={"Inrule Suggestion:"}
                          value={values.inRuleSuggestion}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Permission>
            </Grid>
            {DisciplineServiceDetailsValues.deniedVisits > 0 && (
              <Grid container sx={Gridbodytemplate}>
                <Grid item xs={11.5} sx={TemplateGrid}>
                  <Typography
                    variant="subtitle1"
                    color={colors.fonts[2]}
                    fontWeight={fontWeight.Weight[4]}
                    pb={"1.2rem"}
                  >
                    When denied your home health services because:
                  </Typography>
                </Grid>
                <Grid item xs={11.5}>
                  <Typography
                    variant="subtitle2"
                    color={colors.fonts[2]}
                    fontWeight={fontWeight.Weight[2]}
                    pb={"0.7rem"}
                  >
                    Provide your rationale for status chosen below, Your
                    rationale will be included in the deniel letter that is
                    mailed to the patient.
                  </Typography>
                  <Grid item xs={12} pt={"0.8rem"}>
                    <FormControl sx={DescriptionStyle} variant="standard">
                      <FormInputText
                        name="rationale"
                        control={control}
                        label="Add Description Here..."
                        inputVariant="outlined"
                        inputRows={5}
                        multiline={true}
                        handleChange={(
                          event: React.ChangeEvent<
                            HTMLInputElement | HTMLTextAreaElement
                          >
                        ) => handleDescription(event, "rationale")}
                        textLength={UrgencyReasonTextLength}
                        readOnly={getDisabledFlag(
                          referralDisciplines.serviceStatusId
                        )}
                      />
                    </FormControl>
                    {CharacterCount(
                      getValues("rationale") ? getValues("rationale").length : 0
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={11.5} pt={"1.5rem"}>
                  <Typography
                    variant="subtitle1"
                    color={colors.fonts[2]}
                    fontWeight={fontWeight.Weight[4]}
                    pb={"1.2rem"}
                  >
                    The following are the resources were utilized in the
                    decision to deny services.
                  </Typography>
                </Grid>
                <Grid item xs={11.5}>
                  <Typography
                    variant="subtitle2"
                    color={colors.fonts[2]}
                    fontWeight={fontWeight.Weight[2]}
                    pb={"0.7rem"}
                  >
                    Describe the resources you used when making your decision,
                    your description will be included in the denial letter that
                    is mailed to the patient.
                  </Typography>
                  <Grid item xs={12} pt={"0.8rem"} pb={"0.8rem"}>
                    <FormControl sx={DescriptionStyle} variant="standard">
                      <FormInputText
                        name="resourceDescription"
                        control={control}
                        label="Add Description Here..."
                        inputVariant="outlined"
                        inputRows={5}
                        multiline={true}
                        handleChange={(
                          event: React.ChangeEvent<
                            HTMLInputElement | HTMLTextAreaElement
                          >
                        ) => handleDescription(event, "resourceDescription")}
                        textLength={UrgencyReasonTextLength}
                        readOnly={getDisabledFlag(
                          referralDisciplines.serviceStatusId
                        )}
                      />
                    </FormControl>
                    {CharacterCount(
                      getValues("resourceDescription")
                        ? getValues("resourceDescription").length
                        : 0
                    )}
                  </Grid>
                </Grid>
                {isSpanishLanguage() && (
                  <Grid container>
                    <Grid item xs={11.5} sx={TranslationText} pb={"0.5rem"}>
                      <Box>
                        <Typography
                          variant="h2"
                          color={colors.fonts[2]}
                          fontWeight={fontWeight.Weight[6]}
                        >
                          Denial Rationale Translation
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={11.5}>
                      <Typography
                        variant="subtitle2"
                        color={colors.fonts[2]}
                        fontWeight={fontWeight.Weight[3]}
                        pb={"0.5rem"}
                      >
                        When denied your home health services because:
                      </Typography>
                    </Grid>
                    <Grid item xs={11.5}>
                      <Typography
                        variant="subtitle2"
                        color={colors.fonts[2]}
                        fontWeight={fontWeight.Weight[2]}
                        pb={"0.7rem"}
                      >
                        Provide your rationale for status chosen below, Your
                        rationale will be included in the denial letter that is
                        mailed to the patient.
                      </Typography>
                      <Grid item xs={12} pt={"0.8rem"}>
                        <FormControl sx={DescriptionStyle} variant="standard">
                          <FormInputText
                            name="otherRationale"
                            control={control}
                            label="Add Description Here..."
                            inputVariant="outlined"
                            inputRows={5}
                            multiline={true}
                            handleChange={(
                              event: React.ChangeEvent<
                                HTMLInputElement | HTMLTextAreaElement
                              >
                            ) => handleDescription(event, "otherRationale")}
                            textLength={UrgencyReasonTextLength}
                            readOnly={getDisabledFlag(
                              referralDisciplines.serviceStatusId
                            )}
                          />
                        </FormControl>
                        {CharacterCount(
                          getValues("otherRationale")
                            ? getValues("otherRationale").length
                            : 0
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={11.5} pt={"1.5rem"}>
                      <Typography
                        variant="subtitle2"
                        color={colors.fonts[2]}
                        fontWeight={fontWeight.Weight[3]}
                        pb={"0.5rem"}
                      >
                        The following are the resources were utilized in the
                        decision to deny services:
                      </Typography>
                    </Grid>
                    <Grid item xs={11.5}>
                      <Typography
                        variant="subtitle2"
                        color={colors.fonts[2]}
                        fontWeight={fontWeight.Weight[2]}
                        pb={"0.7rem"}
                      >
                        Describe the resources you used when making your
                        decision, your description will be included in the
                        denial letter that is mailed to the patient.
                      </Typography>
                      <Grid item xs={12} pt={"0.8rem"} pb={"0.8rem"}>
                        <FormControl sx={DescriptionStyle} variant="standard">
                          <FormInputText
                            name="otherResourceDescription"
                            control={control}
                            label="Add Description Here..."
                            inputVariant="outlined"
                            inputRows={5}
                            multiline={true}
                            handleChange={(
                              event: React.ChangeEvent<
                                HTMLInputElement | HTMLTextAreaElement
                              >
                            ) =>
                              handleDescription(
                                event,
                                "otherResourceDescription"
                              )
                            }
                            textLength={UrgencyReasonTextLength}
                            readOnly={getDisabledFlag(
                              referralDisciplines.serviceStatusId
                            )}
                          />
                        </FormControl>
                        {CharacterCount(
                          getValues("otherResourceDescription")
                            ? getValues("otherResourceDescription").length
                            : 0
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {values.stageName ===
                  ReferralDisciplinesStages.ClinicalReview &&
                  serviceStatusId === true && (
                    <Grid
                      item
                      xs={11.5}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: "1rem 0rem",
                      }}
                    >
                      <CustomButton
                        label="SEND FOR PHYSICIAN REVIEW"
                        handleClick={handleSubmit(onSubmit)}
                      />
                    </Grid>
                  )}
              </Grid>
            )}
          </Grid>
          <Permission
            controlId={`|${AuthDetailsAccess.DISCIPLINE_SERVICE_DETAILS_PHYSICIAN_REVIEW_READ}|${AuthDetailsAccess.DISCIPLINE_SERVICE_DETAILS_PHYSICIAN_REVIEW_UPDATE}`}
            passThrough
          >
            {(isDisabled: boolean) => (
              <>{
                (values.stageName === ReferralDisciplinesStages.PhysicianReview || values.agreedByPhysician != null) ? (
                  <Grid container sx={Gridbody}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        color={colors.black[2]}
                        fontWeight={fontWeight.Weight[5]}
                      >
                        PHYSICIAN REVIEW
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle2"
                        color={colors.fonts[2]}
                        fontWeight={fontWeight.Weight[2]}
                        pt={"0.5rem"}
                      >
                        Please review the denial rationale above and clinical
                        notes. Document your decision accordingly.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider light sx={HorizontalDivider} />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={1}>
                          <Typography
                            variant="subtitle2"
                            color={colors.fonts[2]}
                            fontWeight={fontWeight.Weight[3]}
                            pt={"0.5rem"}
                          >
                            Service Status:
                          </Typography>
                        </Grid>
                        <Grid item xs={0.1}>
                          <Typography
                            variant="subtitle1"
                            fontWeight={fontWeight.Weight[3]}
                            color={colors.red[100]}
                            pt={"0.4rem"}
                          >
                            *
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <RadioGroup
                        row
                        aria-labelledby="policy-groups"
                        name="row-policy-groups"
                        value={getAgreedByPhysician(referralDisciplines.agreedByPhysician)}
                        onChange={handlePhysicianReview}
                      >
                        <FormControlLabel
                          value="Agree"
                          control={
                            <Radio
                              disabled={values.stageName !== ReferralDisciplinesStages.PhysicianReview || isDisabled}
                            />}
                          label={
                            <Typography
                              variant="subtitle1"
                              color={colors.black[2]}
                              fontWeight={fontWeight.Weight[5]}
                            >
                              I have reviewed and agree with the criteria
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="Disagree"
                          control={
                            <Radio
                              disabled={values.stageName !== ReferralDisciplinesStages.PhysicianReview || isDisabled}
                            />}
                          label={
                            <Typography
                              variant="subtitle1"
                              color={colors.black[2]}
                              fontWeight={fontWeight.Weight[5]}
                            >
                              Request requires further review
                            </Typography>
                          }
                        />
                      </RadioGroup>
                      {physicianReviewStatus === true && (
                        <Typography
                          variant="subtitle2"
                          color={colors.red[100]}
                          fontWeight={fontWeight.Weight[2]}
                          pt={"0.5rem"}
                        >
                          Service Status Required
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                ) : null}
              </>
            )}
          </Permission>
          <Grid container>
            <NotesTable
              list={disciplineNoteList}
              totalRows={disciplineNoteRowCount}
              handlePageChange={handlePageChange}
              page={page}
              headerLabel={DisciplineNoteValues}
              title={NotesReviewData.DISCIPLINE_NOTES}
              serviceRequestDisciplineId={getValue(
                disciplineRecord,
                "serviceRequestDisciplineId"
              )}
              disableButtons={disablebuttons}
              setdisableButtons={setDisableButtons}
              getDisciplineLevelNotes={getDisciplineLevelNotes}
              saveDisciplineLevelNotesData={saveDisciplineLevelNotesData}
              updateDisciplineLevelNoteData={updateDisciplineLevelNoteData}
              GetDisciplineLevelNotesData={GetDisciplineLevelNotesData}
              saveDisciplineLevelNotesDataResp={
                saveDisciplineLevelNotesDataResp
              }
              updateDisciplineLevelNoteDataresp={
                updateDisciplineLevelNoteDataresp
              }
              addControlId={`${AuthDetailsAccess.DISCIPLINE_SERVICE_DETAILS_ADD_NOTES_CREATE}|${AuthDetailsAccess.DISCIPLINE_SERVICE_DETAILS_ADD_NOTES_READ}|`}
              editControlId={`|${AuthDetailsAccess.DISCIPLINE_SERVICE_DETAILS_ADD_NOTES_READ}|${AuthDetailsAccess.DISCIPLINE_SERVICE_DETAILS_ADD_NOTES_UPDATE}`}
              setRenderDisciplineNotes={setRenderDisciplineNotes}
              renderDisciplineNotes={renderDisciplineNotes}
              pageName={pageName}
              setPageName={setPageName}
              type={NoteType.DISCIPLINE_NOTE}
            />
          </Grid>
        </Grid>
        <Grid container pt={"1.5rem"} pb={"1rem"}>
          <Grid item xs={12} sx={ButtonGrid}>
            <Box sx={displayFlex}>
              <Button variant="contained" onClick={() => handleAssignStaff()}>
                ASSIGN STAFF
              </Button>
              <Button
                sx={CancelBtn}
                onClick={() => {
                  handleBack();
                  setDisableButtons(false);
                  handleClick();
                }}
              >
                CANCEL
              </Button>
              <Box pl={"1.5rem"}>
                <Permission
                  controlId={`||${AuthDetailsAccess.DISCIPLINE_SAVE_SERVICE_DETAILS_UPDATE}`}
                >
                  <Button
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                    disabled={disablebuttons === true ? true : false}
                  >
                    SAVE SERVICE DETAILS
                  </Button>
                </Permission>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

type VisitCounterProps = {
  label: string;
  name: string;
  isRequired?: boolean;
  handleDecreaseCounter: any;
  handleIncreaseCounter: any;
  handleChange: any;
  control: any;
  helper?: any;
  inputType?: string;
  readOnly?: boolean;
  requestedVisitValidator?: () => boolean;
};

const VisitCounter = ({
  label,
  name,
  isRequired,
  handleDecreaseCounter,
  handleIncreaseCounter,
  handleChange,
  control,
  helper,
  inputType,
  readOnly,
  requestedVisitValidator,
}: VisitCounterProps) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={displayFlex}>
          <Typography
            variant="body1"
            color={colors.fonts[2]}
            fontWeight={fontWeight.Weight[2]}
            pl={"1rem"}
          >
            {label}
          </Typography>
          {isRequired && (
            <Typography
              variant="subtitle1"
              fontWeight={fontWeight.Weight[3]}
              color={colors.red[100]}
              pl={"0.4rem"}
            >
              *
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid item xs={2} sx={FaMinusIconGrid}>
        <FaMinus
          onClick={handleDecreaseCounter}
          style={readOnly ? IconDisabled : FaIconBox}
          color={colors.fonts[18]}
        />
      </Grid>
      <Grid item xs={4} pt={"1.2rem"}>
        <Controller
          name={name}
          control={control}
          rules={{ ...helper, ...{ validate: requestedVisitValidator } }}
          disabled={readOnly}
          render={({
            field: { ref, ...fieldProps },
            fieldState: { error },
          }) => (
            <TextField
              variant="standard"
              {...fieldProps}
              value={fieldProps.value != -1 ? fieldProps.value : ""}
              sx={RequestedVisits}
              required={helper && helper.required && helper.required.value}
              inputProps={{
                min: 0,
                style: { textAlign: "center" },
                maxLength: 4,
              }}
              inputRef={ref}
              onChange={(event) => {
                handleChange(event);
              }}
              onInput={(e) => {
                handleInputEvent(e, inputType);
              }}
              helperText={error ? error.message : ""}
              error={error ? true : false}
            />
          )}
        />
      </Grid>
      <Grid item xs={2.5} sx={RequestedVisitIcon}>
        <FaPlus
          onClick={handleIncreaseCounter}
          style={readOnly ? IconDisabled : FaIconBox}
          color={colors.fonts[18]}
        />
      </Grid>
    </Grid>
  );
};

export default DisciplineServiceDetails;
