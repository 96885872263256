import { styled } from "@mui/system";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

import { colors, others } from "../../../../../styles/colors";

export const ModalGrid = () => ({
  position: "absolute",
  top: "12%",
  left: "15%",
  right: "10%",
  width: "auto",
  height: "auto",
  backgroundColor: colors.backgroundColor[7],
  padding: "1rem 1.5rem",
  borderRadius: "0.5rem",
  marginLeft: "1rem",
  zIndex: 10
});

export const PhysicianModalGrid = () => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80rem",
  height: "auto",
  backgroundColor: colors.backgroundColor[7],
  padding: "1rem 1.5rem",
  borderRadius: "0.5rem",
});

export const ContentBox = () => ({
  display: "flex",
  flexDirection: "row",
});

export const ModalHeaderBox = () => ({
  padding: "0 0.5rem",
});

export const ModalTitle = {
  textTransform: "uppercase",
  paddingTop: "0.6rem",
  paddingBottom: "0.3rem",
};

export const PhysicianModalTitle = {
  textTransform: "uppercase",
  paddingTop: "0.8rem",
  paddingBottom: "0.3rem",
};

export const ModalSubtitle = {
  paddingTop: "0.3rem",
  paddingBottom: "0.4rem",
};

export const ClearServiceModalSubtitle = {
  paddingTop: "0.25rem",
  paddingLeft: "0.6rem",
};

export const IconBox = () => ({
  paddingTop: "0.2rem",
});

export const Crossicon = {
  color: colors.red[80],
  opacity: 2,
  width: "1.8rem",
  height: "1.8rem",
  cursor: "pointer",
};

export const GridContainer = () => ({
  background: others.otherColors[33],
  boxShadow: `0.0487rem 0.15rem 0.78rem ${colors.dropShadow[4]}`,
  opacity: 1,
  padding: "1rem 1.2rem",
  borderRadius: "1.2rem",
});

export const SearchResultGrid = () => ({
  backgroundColor: colors.backgroundColor[7],
  opacity: 1,
  padding: "1rem 0.8rem",
  borderRadius: "1.2rem",
});

export const SearchGridContainer = () => ({
  background: others.otherColors[33],
  boxShadow: `0.0487rem 0.15rem 0.78rem ${colors.dropShadow[4]}`,
  opacity: 1,
  padding: "1.5rem 0.5rem",
  borderRadius: "1.2rem",
});

export const SubtitleHorizontalDivider = () => ({
  margin: "1rem 0",
  width: "98%",
});

export const FooterBox = () => ({
  opacity: 1,
  margin: "0.5rem",
  display: "flex",
  paddingTop: "1rem",
  justifyContent: "end",
});

export const SearchTitleGrid = () => ({
  paddingBottom: "1rem",
  paddingLeft: "0.5rem",
});

export const SearchTitle = () => ({
  paddingLeft: "1rem",
  paddingBottom: "0.8rem",
});

export const SearchContent = () => ({
  paddingLeft: "1.5rem",
});

export const CancelButton = () => ({
  background: others.otherColors[33],
});

export const Helpicon = () => ({
  display: "flex",
  alignItems: "center",
});

export const HeaderHelpOutlineOutlinedIcon: any = styled(
  HelpOutlineOutlinedIcon
)(() => ({
  height: "1.25rem",
  width: "1.25rem",
  display: "flex",
  color: colors.fonts[26],
}));

export const CancelProviderModalGrid = () => ({
  position: "absolute",
  left: "49%",
  top: "35%",
  transform: "translate(-50%, -50%)",
  width: "36%",
  height: "auto",
  bgcolor: "background.paper",
  padding: "1rem 1rem 1rem 1.5rem",
  borderRadius: "1.5rem",
  marginLeft: "1.5rem",
  zIndex: 10
});