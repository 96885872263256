import dayjs from "dayjs";
import {
  get,
  isNull,
  isUndefined,
  isNil as isNill,
  isEmpty,
  indexOf,
  isArray,
} from "lodash";
import { Dispatch } from "redux";
import utc from "dayjs/plugin/utc";
import { UseFormSetError } from "react-hook-form";

import SnackbarUtils from "./SnackbarProvider";
import {
  IS_LOCALHOST,
  REACT_APP_ENDPOINT_ENV,
  configUrlForBlob,
} from "../constants/Config";
import { CloudEnvironmentConfig } from "../constants/AppConfigs";
import Logger from "./logHandlers/Logger";
import {
  ReferralResponseMessages,
  ServiceStatus,
  UrgencyStatus,
} from "../constants/AllPatientRecord";
import { FormattedNumber } from "./PhoneNumber";
import { extractFields } from "../constants/PatientList";
import { DEFAULT_PAGE, PER_PAGE } from "../constants/FunctionalUtils";
import { DocumentType } from "../constants/Document";
import {
  Gender,
  URGENCY_STATUS_EXPEDITED_ID,
  requiredFieldMsg,
} from "../constants/Constants";
import {
  alphabetSpaceAndHyphen,
  onlyNumAlphabetRegex,
} from "../constants/Regex";
import {
  PatientRequest,
  ReAuthReferralRequest,
  ReceivedMethods,
} from "../models/Patient";
import { SearchFilterModel } from "../models/Filter";
import {
  DropdownOption,
  DropdownOptionKeys,
} from "../components/formComponents/constant/FormComponents";
import { ExpeditedOptionColor } from "../styles/common/style";
import { colors, others } from "../styles/colors";
import { LofColumn } from "../models/Notes";
import { ReferralDiscipline } from "../models/Disciplines";

//Extend utc to dayjs
dayjs.extend(utc);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - Param value is mandatory
export const isNil = (value: any) => {
  if (value == undefined) {
    return true;
  }
  return false;
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - Param value is mandatory
export const length = (value: any) => {
  if (value !== undefined && value.length > 0) {
    return true;
  }
  return false;
};

// tslint:disable-next-line:no-empty
export function neverReached() {
  return null;
}

//below function used for covert byte array to image
export const avator = (byteArray: string) => {
  if (!isNil(byteArray) && byteArray !== "/broken-image.jpg") {
    return `data:image/png;base64,${byteArray}`;
  }
  return "/broken-image.jpg";
};

//below function used for apply css for tabs
export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

//below function used for sending response to api call
export const get404InitialData = (response: any, initialData: any) => {
  return isNull(response) || isEmpty(response) ? initialData : response;
};

//below function used for sending error response to api call
export const ErrorResponse = (error: any) => {
  const msg = "Something went wrong!";
  switch (error.status) {
    case 422:
    case 400:
      return error;
    case 404:
      return error;
    default:
      SnackbarUtils.warning(msg);
      break;
  }
};

// function to get user image by blobid
export const ProfileImage = (blobId: number) => {
  return `${configUrlForBlob}api/ProfileImages/GetBlobById/${blobId}`;
};

//function to get the value at path of object
export const getValue = (
  inputObj: any,
  nestedProp: string,
  defaultValue?: any
) => {
  if (isUndefined(defaultValue)) {
    defaultValue = null;
  }
  return get(inputObj, nestedProp, defaultValue);
};
/**
 * Method to execute all init calls post config Load
 *
 */
export const initAfterConfigLoad = (): void => {
  Logger.suppressConsole();
};

/**
 * Method to get the json config file name for development and non development environmnet. Default is config.json
 * For development environment it will return config.json file based on REACT_APP_ENDPOINT_ENV value
 *
 * @returns {string} This is the json config file name to get from the pipeline or local path
 */
export const GetConfigFileName = (): string => {
  let fileName = "/config";

  if (IS_LOCALHOST) {
    const selectedEndpointEnv: string = REACT_APP_ENDPOINT_ENV;

    switch (selectedEndpointEnv) {
      case CloudEnvironmentConfig.SB:
        fileName = `${fileName}-sb`;
        break;
      case CloudEnvironmentConfig.QA:
        fileName = `${fileName}-qa`;
        break;
      case CloudEnvironmentConfig.DEV:
        fileName = `${fileName}-dev`;
        break;
      case CloudEnvironmentConfig.STG:
        fileName = `${fileName}-stg`;
        break;
      case CloudEnvironmentConfig.SE:
        fileName = `${fileName}-se`;
        break;
      case CloudEnvironmentConfig.DD:
        fileName = `${fileName}-dd`;
        break;
      case CloudEnvironmentConfig.QZ:
        fileName = `${fileName}-qz`;
        break;
    }
  }

  fileName = `${fileName}.json`;

  return fileName;
};

//Function to set scroll at top
export const scrollTop = () => {
  return window.scrollTo(0, 0);
};

export const smoothScroll = (currentOffsetTop: number) => {
  window.scrollTo({
    top: currentOffsetTop,
    behavior: "smooth",
  });
}

/**
 * @param {Dispatch}  dispatch -redux dispatch
 * @param {any} response - api response
 * @param {any} errorDispatch - dispatch type to set error response
 * @param {any} successDispatch - dispatch type to set success response
 * @param {any} successMessage - message to display success response
 * @param {any} errorMessage - message to display error response
 */
export const DispatchResponse = (
  dispatch: Dispatch,
  response: any,
  errorDispatch: any,
  successDispatch: any,
  successMessage?: any,
  errorMessage?: any
) => {
  switch (response.status) {
    case 422:
    case 400:
      dispatch({
        response,
        type: errorDispatch,
      });
      if (errorMessage) {
        SnackbarUtils.error(ReferralResponseMessages(errorMessage));
      }
      break;
    case 200:
    case 201:
      dispatch({
        response: response.data,
        type: successDispatch,
      });
      if (successMessage) {
        SnackbarUtils.success(ReferralResponseMessages(successMessage));
      }
      break;
    default:
      break;
  }
};

/**
 *
 * @param payload api payload
 * @returns paylaod in query string format
 */
export const GetQueryString = (payload: any) => {
  const QueryString = Object.keys(payload)
    .map((key) =>
      payload[key] !== null || undefined ? key + "=" + payload[key] : ""
    )
    .filter((str) => str !== "") // Filter out empty strings
    .join("&");

  return QueryString;
};
/**
 *
 * @param phoneNumber string formatted/not formatted
 * @returns formatted phone number
 */
export const getFormattedPhoneNo = (phoneNumber: string | null | undefined) => {
  if (isNull(phoneNumber) || isUndefined(phoneNumber)) {
    return phoneNumber;
  } else {
    return FormattedNumber(phoneNumber.replace(/\D/g, ""), phoneNumber.length);
  }
};

/**
 *
 * @param referralId string referralId
 * @returns return string referral id if it includes ?
 */
export const getReferralId = (referralId: string) => {
  if (referralId.includes("?")) {
    return referralId.split("?")[0];
  }
  return referralId;
};

export const getServiceRequestNumber = (serviceRequestNumber: string) => {
  let serviceNumber = "";
  if (!isNill(serviceRequestNumber)) {
    serviceNumber = getValue(serviceRequestNumber.split("-"), "[1]", "");
  }

  return serviceNumber;
};

/**
 *
 * @param list any list
 * @param includeOriginalObject boolean includeOriginalObject
 * @returns return list array with extracted fields
 */
export const getListResult = (list: any, includeOriginalObject = false) => {
  const result =
    list &&
    list.map((v: any) => extractFields(v, false, includeOriginalObject));
  return result;
};

const extractDropdownOption = (item: any, keyNames: DropdownOptionKeys) => {
  const result: any = {};
  for (const [key, value] of Object.entries(keyNames)) {
    result[key] = item[value];
  }
  return result;
};

/**
 *
 * @param list array
 * @param keyNames dynamic key name {label: "key1", value: "key2"}
 * @returns array of dropdown options []{label: "label", value: "value"}
 */
export const getList = (list: any[], keyNames: DropdownOptionKeys) => {
  if (isArray(list)) {
    return list.map((v: any) => extractDropdownOption(v, keyNames));
  }
  return [];
};

/**
 *
 * @param patientId number patientId
 * @returns query string data
 */
export const queryStringData = (patientId: number) => {
  return {
    patientId: Number(patientId),
    contains: true,
    pageNo: DEFAULT_PAGE,
    pageSize: PER_PAGE,
  };
};

/**
 *
 * @param array T[] array
 * @param from number from
 * @param to number to
 * @returns array
 */
export function arrayMove<T>(array: T[], from: number, to: number) {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
}

/**
 * allow only numbers or string as per regex filter
 * @param {any}  e - input field value typed in
 * @param {string} type - allowed filter type - onlyNumber or onlyText or onlyTextAndSpace
 *
 */
export const handleInputEvent = (e: any, type: string | undefined) => {
  const initialValue = e.target.value;
  const selectionStart = e.target.selectionStart;

  let newSelectionStart = selectionStart;

  switch (type) {
    case "onlyNumber":
      e.target.value = initialValue.replace(/[^0-9]*/g, "");
      break;
    case "onlyText":
      e.target.value = initialValue.replace(/[^a-zA-Z]/g, "");
      break;
    case "onlyTextAndSpace":
      e.target.value = initialValue.replace(/[^a-zA-Z\s]/g, "");
      break;
    case "onlyTextSpaceAndHyphen":
      e.target.value = initialValue.replace(alphabetSpaceAndHyphen, "");
      break;
    case "onlyNumAlphabetRegex":
      e.target.value = initialValue.replace(onlyNumAlphabetRegex, "");
      break;
  }
  if (initialValue !== e.target.value) {
    const removedCharsBeforeCursor = initialValue
      .substring(0, selectionStart)
      .split("")
      .filter((char: any) => !e.target.value.includes(char)).length;
    newSelectionStart = selectionStart - removedCharsBeforeCursor;
    e.target.setSelectionRange(newSelectionStart, newSelectionStart);
    e.stopPropagation();
  }
};

/**
 *
 * @param value string value
 *
 * @returns extracted doc type
 */
export const extractDocType = (value: string | null | undefined) => {
  if (!value || !value.includes("/")) {
    return DocumentType.INVALID;
  }
  const splitString: any = value.split("/")[1];
  const format: DocumentType = splitString;
  return format;
};

/**
 *
 * @param docName string docName
 *
 * @returns send fax doc is of below type and reutrn boolean value
 */
export const checkSendFaxDocType = (docName: string | null | undefined) => {
  const docType = extractDocType(docName);
  if (indexOf(["pdf", "jpg", "jpeg", "png"], docType) > -1) {
    return true;
  }
  return false;
};

/**
 *
 * @param type string type
 *
 * @returns gender
 */
export const SelectGender = (type: string) => {
  switch (type) {
    case "F":
      return Gender.FEMALE;
      break;
    case "M":
      return Gender.MALE;
      break;
    case "U":
      return Gender.UNKNOWN;
      break;
    default:
      return "";
      break;
  }
};

/**
 * To get the health plan and benifit plan data from base64 encoded string and separated by "|||"
 * @param {string}  base64String - base64 healthplan id and label string concatenated with "|||"
 * @param {number} index - 0 for healthplan id and 1 for health plan name
 *
 * @returns {string} healthplan id or health plan name
 */
export const getHealthPlanValueType = (
  base64String: string | undefined,
  index: number
): string => {
  return !isUndefined(base64String) && !isNill(base64String)
    ? atob(base64String).split("|||")[index]
    : "";
};

/**
 *
 * @param value any value
 *
 * @returns value or empty string
 */
export const isValid = (value: any) => {
  if (value.toString().toUpperCase().includes("INVALID")) {
    return "";
  }
  return value;
};

/**
 *
 * @param strings any strings
 *
 * @returns concatinated string
 */
export const getConcatStringOrNull = (...strings: any) => {
  return strings
    .filter((str: any) => str != null && str !== undefined)
    .join(" ");
};

/**
 *
 * @param errorResponse any errorResponse
 * @param setError UseFormSetError<any> setError
 *
 * @returns server side error message
 */
export const serverSideErrorMsg = (
  errorResponse: any,
  setError: UseFormSetError<any>
) => {
  return Object.keys(errorResponse).forEach((field) => {
    const messages = errorResponse[field];
    if (messages && isArray(messages)) {
      setError(field as any, {
        type: "server",
        message: messages.join(". "),
      });
    }
  });
};

/**phone number and extension*/
export const getPhoneNoAndExtension = (phoneNo: string, extension: string) => {
  if (!isEmpty(phoneNo)) {
    return phoneNo + `${!isEmpty(extension) ? " ext. " + extension : ""}`;
  }
  return "";
};

/**following function used to show error messages */
export const showErrorMessagesFromResponse = (response: any) => {
  Object.keys(response.data).forEach((field) => {
    return response.data[field].message;
  });
};

/**
 *
 * @param value string value
 *
 * @returns btoa value
 */
export const getBtoaValue = (value: string) => {
  return btoa(value);
};

/**
 *
 * @param value string value
 *
 * @returns getAtob value
 */
export const getAtob = (value: string) => {
  try {
    const decodedString = atob(value);
    const additionalValues = JSON.parse(decodedString);
    return additionalValues;
  } catch (e) {
    return {};
  }
};

/**My Patient Filter */
export const getPatientFilterPayload = (filterStatePayload: any) => {
  const newPayloadObject: any = {};
  for (const key in filterStatePayload) {
    if (
      typeof filterStatePayload[key] === "object" &&
      filterStatePayload[key] !== null &&
      "value" in filterStatePayload[key]
    ) {
      newPayloadObject[key] = filterStatePayload[key].value;
    } else {
      newPayloadObject[key] = filterStatePayload[key];
    }
  }
  return newPayloadObject;
};

/**
 *
 * @param singleOption ReceivedMethods singleOption
 * @param values ReAuthReferralRequest | PatientRequest value
 * @param key string key
 *
 * @returns boolean value
 */
export const fromProviderPortal = (
  singleOption: ReceivedMethods,
  values: ReAuthReferralRequest | PatientRequest | ReferralDiscipline,
  key: string
) => {
  if (singleOption) {
    return (
      singleOption.isIntegrationMethod &&
      getValue(values, key, null) == singleOption.receivedMethodId
    );
  }
  return false;
};

export const setLocalStorage = (key: string, data: any) => {
  return localStorage.setItem(key, JSON.stringify(data));
};

export const getLocalStorage = (key: string) => {
  const data = localStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  }
  return null;
};

/**Filter */
export const getCurrentTabName = (activeTab: number) => {
  switch (activeTab) {
    case 0:
      return "myPatient";
    case 1:
      return "allPending";
    case 2:
      return "incompleteRequest";
    case 3:
      return "staffingQueue";
    case 4:
      return "privateDutyAuth";
    default:
      return "";
  }
};

export const getFilterPayloadAsPerTab = (
  activeTab: number,
  payload: SearchFilterModel
) => {
  switch (activeTab) {
    case 0:
      return payload.myPatient;
      break;
    case 1:
      return payload.allPending;
      break;
    case 2:
      return payload.incompleteRequest;
      break;
    case 3:
      return payload.staffingQueue;
      break;
    case 4:
      return payload.privateDutyAuth;
      break;
    default:
      return payload.allPending;
      break;
  }
};

export const validInputTextLength = (
  inputTextLength: number,
  remainingChar: number
) => {
  return inputTextLength - remainingChar > 0;
};

export const remainingInputCharLength = (
  inputTextLength: number,
  remainingChar: number
) => {
  return inputTextLength - remainingChar;
};

export const messageForInputCharLength = (
  inputTextLength: number,
  remainingChar: number
) => {
  if (inputTextLength - remainingChar >= 0) {
    return `Remaining ${remainingInputCharLength(
      inputTextLength,
      remainingChar
    )} characters.`;
  }
  if (inputTextLength - remainingChar < 0) {
    return `Entered ${
      remainingInputCharLength(inputTextLength, remainingChar) * -1
    } characters more than expected ${inputTextLength} characters.`;
  }
};

export const isExpedited = (key: number | null) => {
  if (key && key == URGENCY_STATUS_EXPEDITED_ID) {
    return ExpeditedOptionColor;
  } else {
    return null;
  }
};

export const serviceStatusColor = (color: string) => {
  switch (color) {
    case ServiceStatus.PENDING:
      return others.otherColors[67];
    case ServiceStatus.PARTIALLY_APPROVED:
      return others.otherColors[68];
    case ServiceStatus.APPROVED:
      return others.otherColors[69];
    case ServiceStatus.DENIED:
      return others.otherColors[70];
    case ServiceStatus.CANCELLED:
      return colors.red[101];
    case ServiceStatus.EXPEDITED:
      return colors.red[100];
    case ServiceStatus.STANDARD:
      return colors.black[5];
    default:
      return "";
  }
};

export const urgencyStatusColor = (color: string) => {
  switch (color) {
    case UrgencyStatus.EXPEDITED:
      return colors.red[100];
    case UrgencyStatus.STANDARD:
      return colors.black[5];
    default:
      return "";
  }
};

/**
 *
 * @param msg message string
 *
 * @returns boolean value which check to show or hide asterisk symbol for required text field
 */

export const checkValidationMsgSymbol = (msg: string) => {
  return !Object.values(requiredFieldMsg).includes(msg);
};

export const isEmptyValue = (obj: { [key: string]: any }): boolean => {
  return Object.values(obj).every(
    (value) => value === null || value === "" || value === undefined
  );
};

export const formatPhoneNumber = (phone: string | null) => {
  if (!isEmpty(phone)) {
    const cleanedPhone = phone && phone.replace(/\D/g, "");
    return cleanedPhone;
  } else {
    return null;
  }
};
/**
 * @param arrayObject DropdownOption[]
 * @param targetValue string
 * @param targetKey keyof DropdownOption
 *
 * @returns a boolean value after checking if the array of object consist of target value against the key
 */
export const isValuePresentInObjects = (
  arrayObject: DropdownOption[],
  targetValue: string,
  targetKey: keyof DropdownOption
) => {
  return arrayObject.some((obj) => obj[targetKey] === targetValue);
};

/**
 * @param num ofColumn[]
 *
 * @returns dynamic columns for level of function
 */
export const generateColumns = (num: number): LofColumn[] => {
  const base = "column";
  return Array.from({ length: num }, (_, index) => ({
    id: `${base}${index + 1}`,
    label: `Column ${index + 1}`,
  }));
};

/**
 * @param item any
 * @param keyPath string
 *
 * @returns dynamic row data for level of function
 */
export const getNoteEntryValue = (item: any, keyPath: string) => {
  if (!keyPath) return item;
  return keyPath.split(".").reduce((acc, part) => acc?.[part], item);
};

/* *
 * @param obj1 json object
 * @param obj2 json object
 *
 * @returns if both of the objects are identical this function will return true;
 */
export const areObjectsEqual = (obj1: any, obj2: any): boolean => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

/* *
 * @param arr any []
 *
 * @returns single object when array contains mixed entries some entries will be with key values and some with only text;
 */

export const convertToObject = (arr: any[]) => {
  const result: any = {};

  arr.forEach((item: any) => {
    let value, text;

    if (typeof item === "string") {
      value = item;
      text = item;
    } else {
      value = item.value;
      text = item.text;
    }

    result[value] = text;

    if (text !== value) {
      result[text] = text;
    }
  });

  return result;
};

/* *
 * @param pages any
 * @param elementName any
 *
 * @returns choices array which is present in elements of surveyjs json pages array when element name matches
 * this choices array includes text and values
 */

export const getChoicesForElement = (pages: any[], elementName: any) => {
  for (const page of pages) {
    for (const element of page.elements) {
      if (element.type === "panel") {
        for (const subElement of element.elements) {
          if (subElement.name === elementName) {
            return subElement.choices || [];
          }
        }
      } else if (element.name === elementName) {
        return element.choices || [];
      }
    }
  }
  return [];
};

/* *
 * @param pages any
 * @param elementName any
 *
 * @returns columns when element name matches
 * these columns includes entries array with text and values
 */

export const getColumnsForElement = (pages: any, elementName: any) => {
  for (const page of pages) {
    for (const element of page.elements) {
      if (element.type === "panel") {
        for (const subElement of element.elements) {
          if (subElement.name === elementName) {
            return subElement.columns || [];
          }
        }
      } else if (element.name === elementName) {
        return element.columns || [];
      }
    }
  }
  return [];
};

/* *
 * @param data any
 *
 * @returns array with text and value pairs
 * this function used to normalize mixed array structure
 */

export const normalizeData = (data:any) => {
  return data.map((item:any) => {
    if (typeof item === 'string') {
      return { value: item, text: item };
    }
    return item;
  });
};

/**
 * @param DefaultLOF any[]
 *
 * @returns LOF array's first object label which is present in DefaultLOF array
 */
export const generateLofRowKeys = (DefaultLOF:any[]) => {
  return DefaultLOF.map((item) => item.LOF[0]?.label).filter((label) => label);
};

export const getFormattedName = (str: string) => {
  return str.toLowerCase().replace(/(^|\s|-)(\w)/g, (_, m1, m2) => m1 + m2.toUpperCase());
};
